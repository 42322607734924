import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import "./styles/businessBackofficeStyles.scss";
import CrearRadicadoInterno from "./InternalFiling";
import CrearRadicadoExterno from "./ExternalFiling";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

function Inbox(props) {
  const [activeView, setActiveView] = useState("EXTERNO"); 

  return (
    <div style={{ backgroundColor: "black" }}>
      <Row style={{ marginTop: "20px" }}>
        <Col xs={12} style={{ textAlign: "center" }}>
          <h1 className="business-viewpanel-title" style={{ color: "white", marginBottom: "40px" }}>Gobernación del Valle</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          <div className={activeView === "EXTERNO" ? "active-business-tab" : "business-tab"} onClick={() => setActiveView("EXTERNO")}>
            <h3 className="business-viewpanel-tab-title">Crear Radicado Externo</h3>
          </div>
          <div className={activeView === "INTERNO" ? "active-business-tab" : "business-tab"} onClick={() => setActiveView("INTERNO")}>
            <h3 className="business-viewpanel-tab-title">Crear Radicado Interno</h3>
          </div>
        </Col>
      </Row>
      {activeView === "EXTERNO" && <CrearRadicadoExterno />}
      {activeView === "INTERNO" && <CrearRadicadoInterno />}
    </div>
  );
}

export default connect(select)(Inbox);