import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Spinner } from "reactstrap";
import "./styles/businessBackofficeStyles.scss";
import {
  priorityOptions,
  documentOptions,
  employees,
  tipificationOptions,
  groupOptions,
  dependenceOptions,
  colombianCities,
  documentTypeOptions,
  responseMediumOptions,
  classesOptions,
  employeesByDependence,
} from "../config";
import { backUrl, preMyceliumURL, mantisApiKey } from "../utils/backURL";

function DocumentActions(props) {
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const [previewFile, setPreviewFile] = useState(null);
  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [radicado, setRadicado] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [searchTerms, setSearchTerms] = useState({});
  const [formValues, setFormValues] = useState({
    "Radicado": "",
    "Fecha de Ingreso": getCurrentDate(),
    "Prioridad": priorityOptions[0]?.value || "",
    "Grupo": groupOptions[0]?.value || "",
    "Clase Correspondencia": classesOptions[0]?.value || "",
    "Tipificación": tipificationOptions[0]?.value || "",
    "Medio Recepción": documentOptions[0]?.value || "",
    "Para": employees[0]?.value || "",
    "Dependencia": dependenceOptions[0]?.value || "",
    "Asunto": "",
    "No. Hojas": "",
    "Número de Comunicación": "",
    "Nombre Remitente": "",
    "Tipo de Documento": documentTypeOptions[0]?.value || "",
    "Número de Documento": "",
    "Dirección": "",
    "Email": "",
    "No. Celular": "",
    "País": "COLOMBIA",
    "Ciudad": colombianCities[0]?.value || "",
    "Fecha de Remisión": "",
    "Medio de Respuesta": responseMediumOptions[0]?.value || "",
    "Copias del Rótulo": "",
    "Rótulo automático": false,
    "Rótulo papel": false,
    "Requiere Respuesta": false,
    "Observaciones": "",
    "Tipo": "externo",
  });
  const [predictedFields, setPredictedFields] = useState([]);

  useEffect(() => {
    generateRadicado();
  }, []);

  const generateRadicado = () => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const sequentialNumber = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");

    const radicadoNumber = `${year}${month}${sequentialNumber}`;
    setRadicado(radicadoNumber);
    setFormValues((prev) => ({ ...prev, "Radicado": radicadoNumber }));
  };

  const handleFileChange = async (event) => {
    const selectedFiles = event.target.files;
    const filePromises = Array.from(selectedFiles).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.replace(
            /^data:application\/pdf;base64,/,
            ""
          );
          const sizeInBytes = Math.ceil((base64String.length * 3) / 4);
          const mimeType = reader.result.match(/data:(.*);base64/)?.[1] || 'application/pdf';
          const fileExtension = file.name.split('.').pop().toLowerCase();

          resolve({ 
            name: file.name, 
            base64: base64String,
            type: mimeType,
            size: sizeInBytes,
            extension: fileExtension
          });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });

    try {
      const filesData = await Promise.all(filePromises);
      setFiles(filesData);
      if (!isPreviewModalOpen) { 
        setPreviewFile(filesData[0]);
        setIsPreviewModalOpen(true);
      }
    } catch (error) {
      console.error("Error processing files:", error);
    } finally {
      setLoading(false); 
    }
  };

  const handleSubmitFiles = async () => {
    const url = `${backUrl}/upload`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ files }),
      });

      if (!response.ok) {
        throw new Error("Error al enviar los archivos");
      }

      const data = await response.json();

      if (!Array.isArray(data.responses) || data.responses.length === 0) {
        console.error("Formato de respuesta inesperado:", data);
        throw new Error("La respuesta del servidor no contiene datos válidos");
      }

      const responseObject = data.responses[0];
      setFiles((prev) => prev.map(file => ({ 
        ...file, 
        file_url: responseObject.file_url,
        remote_file: responseObject.remote_file
      })));
      const updatedValues = { ...formValues };
      const predicted = [];
      for (const [key, value] of Object.entries(responseObject)) {
        if (
          value !== undefined && 
          value !== null && 
          value !== "NULL" && 
          value !== "" && 
          value !== "null"
        ) {
          updatedValues[key] = value;
          predicted.push(key);
        }
      }
      setFormValues(updatedValues);
      setPredictedFields(predicted);
      setIsPreviewModalOpen(false);
    } catch (error) {
      console.error("Error al enviar los archivos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitForm = async () => {
    const selectedEmployee = availableEmployees.find(emp => emp.value === formValues["Para"]);
    
    // Combinar los datos del formulario con los campos adicionales requeridos
    const premicilioData = {
      form_data: {
        ...formValues
      },
      document_id: null,
      main_key: formValues["Radicado"],
      mantis_business_id: "67194978e97e646e3429b270", 
      file_metadata: {
        "file_name": files[0].name, 
        "file_size": files[0].size, 
        "file_type": files[0].type, 
        "file_base64": files[0].base64,
        "file_url": null,
        "remote_file": files[0].remote_file,

      },
      base64_string: files[0].base64,
      embed_file_group: null,
      document_group_object: null,
      client_type: "PQRS",
      file_url: null,
      file_name: files[0].name,
      file_size: files[0].size,
      file_type: files[0].type,
      file_date: formValues["Fecha de Ingreso"],
      file_extension: files[0].extension,
      email_date: null,  
      subject: formValues["Asunto"],
      attachments: files.map(file => ({
        filename: file.name,
        mimeType: file.type,
        attachmentId: file.id || null
      })),
      receiver_email_address: selectedEmployee?.email || "", 
      main_id: null,
      email_type: "VENTANILLA_UNICA",
    };

    console.log("Enviando a premicelio:", premicilioData);

    try {
      setLoading(true);
      const premicilioResponse = await fetch(`${preMyceliumURL}/process`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": mantisApiKey,
        },
        mode: "cors",
        body: JSON.stringify(premicilioData),
      });
      if (!premicilioResponse.ok) {
        const errorData = await premicilioResponse.json();
        throw new Error(errorData.message || "Error al crear el radicado en premicelio");
      }

      const responseData = await premicilioResponse.json();
      console.log("Respuesta de premicelio:", responseData);

      // Actualizar la UI con la respuesta
      alert(`Radicado creado exitosamente`);
      setIsPreviewModalOpen(false);

    } catch (error) {
      console.error("Error al crear radicado en premicelio:", error);
      alert(`Error al crear el radicado: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const renderFilePreviewModal = () => (
    <Modal
      isOpen={isPreviewModalOpen}
      onRequestClose={() => setIsPreviewModalOpen(false)}
      className="file-preview-modal"
      overlayClassName="ReactModal__Overlay"
    >
      <div className="file-preview">
        {files.map((file, index) => (
          <div key={index} className="file-item">
            <h2>Previsualización de {file.name}</h2>
            <div>
              {previewFile && (
                <div>
                  {previewFile.type.startsWith("image/") && (
                    <img
                      src={`data:${previewFile.type};base64,${previewFile.base64}`}
                      alt={previewFile.name}
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  )}
                  {previewFile.type === "application/pdf" && (
                    <iframe
                      src={`data:application/pdf;base64,${previewFile.base64}`}
                      title={previewFile.name}
                      style={{ width: "100%", height: "350px", borderRadius: "10px", border: "1px solid #ccc" }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <button 
        className="document-analysis-module-button" 
        onClick={() => {handleSubmitFiles(); setLoading(true);}}
        disabled={loading}
      >
        {loading ? "Cargando documento..." : "Enviar"}
      </button>
      {loading && <div className="loading-spinner"><Spinner color="blue" /></div>}
    </Modal>
  );

  const sortOptions = (options) => {
    return [...options].sort((a, b) => a.label.localeCompare(b.label));
  };  

  const handleDependencyChange = (e) => {
    const selectedDependency = e.target.value;

    if (!selectedDependency || !employeesByDependence[selectedDependency]) {
      setFormValues({
        ...formValues,
        "Dependencia": selectedDependency,
        "Para": employees[1]?.value
      });
      setAvailableEmployees([]); 
      return;
    }

    const selectedEmployees = employeesByDependence[selectedDependency] || [];
    
    setFormValues({
      ...formValues,
      "Dependencia": selectedDependency,
      "Para": selectedEmployees[0]?.value || "" 
    });

    console.log("SELECTED DEPENDENCY AND EMPLOYEES", selectedDependency, selectedEmployees);
    setAvailableEmployees(selectedEmployees);
  };

  const areAllFieldsValid = () => {
    for (const [key, value] of Object.entries(formValues)) {
      if (!value || value === "" || value === "null" || value === null) {
        return false;
      }
    }
    return true;
  }

  const handleCreateRadicado = () => {
    const emptyFields = [];
    for (const [key, value] of Object.entries(formValues)) {
      if (!value || value === "" || value === "null" || value === null) {
        emptyFields.push(key);
      }
    }

    if (emptyFields.length > 0) {
      alert(`No se puede crear el radicado. Por favor complete los siguientes campos:\n${emptyFields.join('\n')}`);
      return;
    }

    // Si todos los campos están llenos, procede con la creación del radicado
    handleSubmitForm();
  };
  const renderSection = (title, fields) => (
    <div className="document-section">
      <h4 className="document-analysis-module-title">{title}</h4>
      {fields.map((field, index) => (
        <div key={index} className="modal-field">
          <label>
            {field.label}
          </label>
          {field.label === "Radicado" || field.label === "Fecha de Ingreso" || field.label === "País" ? (
            <div className="non-editable-field">
              {formValues[field.label]}
            </div>
          ) : field.type === "select" ? (
            <select
              value={formValues[field.label] || ""}
              onChange={(e) => {
                if (field.label === "Dependencia") {
                  handleDependencyChange(e);
                } else {
                  setFormValues({
                    ...formValues,
                    [field.label]: e.target.value,
                  });
                }
              }}
              className={`${predictedFields.includes(field.label) ? "predicted" : ""} ${
                !formValues[field.label] ? "required" : ""
              }`}
              required
            >
              <option value="" disabled>
                {field.label === "Dependencia" 
                  ? "Seleccione una dependencia"
                  : field.label === "Para"
                  ? "Seleccione un empleado"
                  : `Seleccione ${field.label}`
                }
              </option>
            
              {field.label === "Para" 
                ? sortOptions(availableEmployees)
                      .filter(option => option.value !== "" ) 
                      .map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))
                : sortOptions(field.options)
                    .filter(option => option.value !== "" )
                    .map((option, idx) => (
                      <option key={idx} value={option.value}>
                        {option.label}
                      </option>
                    ))}
            </select>
          ) : field.type === "checkbox" ? (
            <input
              type="checkbox"
              name={field.label}
              checked={formValues[field.label] || false}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.checked,
                })
              }
            />
          ) : field.type === "file" ? (
            <div>
              <input
                id="choose-files"
                type="file"
                className="document-analysis-module-input"
                multiple
                onChange={handleFileChange}
                style={{ display: 'flex', color: 'white' }}
              /> 
            </div>
          ) : field.type === "date" && field.label === "Fecha de Remisión" ? (
            <input
              type="date"
              value={formValues[field.label] || getCurrentDate()}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.value,
                })
              }
              className={`${predictedFields.includes(field.label) ? "predicted" : ""}`}
            />
          ) : (
            <input
              type={field.type}
              value={formValues[field.label] || ""}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  [field.label]: e.target.value,
                })
              }
              readOnly={field.readOnly}
              className={`${predictedFields.includes(field.label) ? "predicted" : ""} ${
                !formValues[field.label] ? "required" : ""
              }`}
              required
            />
          )}
        </div>
      ))}
    </div>
  );

  const formSections = [
    {
      title: "Adjuntos",
      fields: [
        { label: "Adjunta o escanea los archivos del radicado", type: "file", multiple: true, onChange: handleFileChange},
      ],
    },
    {
      title: "Identificación",
      fields: [
        { label: "Radicado", type: "text", readOnly: true, value: radicado },
        { label: "Fecha de Ingreso", type: "text", readOnly: true, value: getCurrentDate() },
        { label: "Prioridad", type: "select", options: priorityOptions },
        { label: "Grupo", type: "select", options: groupOptions },
        { label: "Clase Correspondencia", type: "select", options: classesOptions },
        { label: "Tipificación", type: "select", options: tipificationOptions },
        { label: "Medio Recepción", type: "select", options: documentOptions },
      ],
    },
    {
      title: "Destinatario (Usuario)",
      fields: [
        { label: "Dependencia", type: "select", options: dependenceOptions },
        { label: "Para", type: "select", options: employees },
        { label: "Asunto", type: "text" },
        { label: "No. Hojas", type: "number" },
        { label: "Número de Comunicación", type: "number" },
      ],
    },
    {
      title: "Remitente",
      fields: [
        { label: "Nombre Remitente", type: "text" },
        { label: "Tipo de Documento", type: "select", options: documentTypeOptions },
        { label: "Número de Documento", type: "number" },
        { label: "Dirección", type: "text" },
        { label: "Email", type: "email" },
        { label: "No. Celular", type: "number" },
        { label: "País", type: "text", readOnly: true, value: "COLOMBIA" },
        { label: "Ciudad", type: "select", options: colombianCities },
        { label: "Fecha de Remisión", type: "date", defaultValue: getCurrentDate() },
        { label: "Medio de Respuesta", type: "select", options: responseMediumOptions },
        { label: "Copias del Rótulo", type: "number" },
        { label: "Rótulo Automático", type: "checkbox" },
        { label: "Rótulo Papel", type: "checkbox" },
        { label: "Requiere Respuesta", type: "checkbox" },
      ],
    },
    {
        title: "Observaciones",
        fields: [
            {type: "text"},
        ],
    },
  ];

  return (
    <>
    <div className="document-actions-container-2">
      {formSections.map((section, index) => (
        <div key={index} className="section-divider">
          {renderSection(section.title, section.fields)}
        </div>
      ))}
      {files.length > 0 && renderFilePreviewModal()}
      <button
          onClick={handleSubmitForm}
          className="create-radicado-button"
        >
          Crear Radicado
        </button>
      </div>
    </>
  );
}

export default DocumentActions;