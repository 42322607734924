module.exports = {
  fileUploadLimit: process.env.REACT_APP_FILE_UPLOAD_LIMIT || 40,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
  brand: process.env.REACT_APP_BRAND || "MANTIS",
  priorityOptions: [
    { value: "ALTA", label: "Alta" },
    { value: "MEDIA", label: "Media" },
    { value: "BAJA", label: "Baja" },
  ],
  documentOptions: [
    { value: "VENTANILLA ÚNICA", label: "Ventanilla Única" },
    { value: "SEDE ELECTRÓNICA", label: "Sede Electrónica" },
    { value: "CORREO ELECTRÓNICO", label: "Correo Electrónico" },
  ],
  responseMediumOptions: [
    {value: "CORREO ELECTRÓNICO", label: "Correo Electrónico"},
    {value: "ENTREGA FÍSICA", label: "Entrega Física"},
  ],
  documentTypeOptions: [
    {value: "CÉDULA DE CIUDADANÍA", label: "CÉDULA DE CIUDADANÍA"},
    {value: "CÉDULA DE EXTRANJERÍA", label: "CÉDULA DE EXTRANJERÍA"},
    {value: "PASAPORTE", label: "PASAPORTE"},
    {value: "NIT", label: "NIT"},
    {value: "TARJETA DE IDENTIDAD", label: "TARJETA DE IDENTIDAD"},
  ],
  tipificationOptions: [
    { value: "GENERALES - RECIBIDO", label: "GENERALES - RECIBIDO" },
    { value: "GENERALES - ENVIADO", label: "GENERALES - ENVIADO" },
    { value: "GENERALES - INTERNO", label: "GENERALES - INTERNO" },
    { value: "DERECHOS DE PETICIÓN", label: "DERECHOS DE PETICIÓN" },
    { value: "ACCIONES DE TUTELA", label: "ACCIONES DE TUTELA" },
    { value: "RECURSOS", label: "RECURSOS" },
    { value: "OTROS", label: "OTROS" },
    { value: "PQRSDF", label: "PQRSDF" },
    { value: "ACCIONES DE CUMPLIMIENTO", label: "ACCIONES DE CUMPLIMIENTO" },
    { value: "CONCILIACIONES", label: "CONCILIACIONES" },
    { value: "PROCESOS EJECUTIVOS", label: "PROCESOS EJECUTIVOS" },
    { value: "RECURSOS", label: "RECURSOS" },
    { value: "TRÁMITES", label: "TRÁMITES" },
    { value: "PRODUCTOS", label: "PRODUCTOS" },
    { value: "OTROS PQRS", label: "OTROS PQRS" },
    { value: "SOPORTESBANCOS", label: "SOPORTES BANCOS" },
    { value: "DECRETOS Y ACUERDOS MUNICIPALES Y DISTRITALES", label: "DECRETOS Y ACUERDOS MUNICIPALES Y DISTRITALES" },
    { value: "PASIVO PENSIONAL", label: "PASIVO PENSIONAL" },
    { value: "ACCIONES JUDICIALES", label: "ACCIONES JUDICIALES" },
    { value: "TRÁMITES RENTAS", label: "TRÁMITES RENTAS" },
    { value: "PROCESO TRIBUTARIO", label: "PROCESO TRIBUTARIO" },
  ],
  colombianCities: [
    { value: "BOGOTA", label: "BOGOTA" },
    { value: "MEDELLIN", label: "MEDELLIN" },
    { value: "CALI", label: "CALI" },
    { value: "BARRANQUILLA", label: "BARRANQUILLA" },
    { value: "CARTAGENA", label: "CARTAGENA" },
    { value: "CUCUTA", label: "CUCUTA" },
    { value: "BUCARAMANGA", label: "BUCARAMANGA" },
    { value: "PEREIRA", label: "PEREIRA" },
    { value: "IBAGUE", label: "IBAGUE" },
    { value: "SANTA MARTA", label: "SANTA MARTA" },
    { value: "MANIZALES", label: "MANIZALES" },
    { value: "VILLAVICENCIO", label: "VILLAVICENCIO" },
    { value: "PASTO", label: "PASTO" },
    { value: "MONTERIA", label: "MONTERIA" },
    { value: "ARMENIA", label: "ARMENIA" },
    { value: "NEIVA", label: "NEIVA" },
    { value: "POPAYAN", label: "POPAYAN" },
    { value: "SINCELEJO", label: "SINCELEJO" },
    { value: "RIOHACHA", label: "RIOHACHA" },
    { value: "TUNJA", label: "TUNJA" },
    { value: "VALLEDUPAR", label: "VALLEDUPAR" },
    { value: "QUIBDO", label: "QUIBDO" },
    { value: "FLORENCIA", label: "FLORENCIA" },
    { value: "YOPAL", label: "YOPAL" },
    { value: "SAN ANDRES", label: "SAN ANDRES" },
    { value: "LETICIA", label: "LETICIA" },
    { value: "BUENAVENTURA", label: "BUENAVENTURA" },
    { value: "TULUA", label: "TULUA" },
    { value: "PALMIRA", label: "PALMIRA" },
    { value: "SOACHA", label: "SOACHA" }
],
  employees: [
    { value: "", disabled: true, label: "Seleccione un empleado"},
    { value: "ANA JESSICA MOSQUERA COBO", label: "ANA JESSICA MOSQUERA COBO" },
    { value: "YUDCIEL ANDREA CHAPARRO", label: "YUDCIEL ANDREA CHAPARRO" },
    { value: "LUIS EDUARDO CARVAJAL", label: "LUIS EDUARDO CARVAJAL" },
    { value: "MARIA ISABEL HERNANDEZ JARAMILLO", label: "MARIA ISABEL HERNANDEZ JARAMILLO" },
    { value: "CRISTIAN DAVID SOLIS GRANJA", label: "CRISTIAN DAVID SOLIS GRANJA" },
    { value: "MAGNOLIA HERNANDEZ LUCUMI", label: "MAGNOLIA HERNANDEZ LUCUMI" },
    { value: "MARIA ISABEL VERGARA SANCHEZ", label: "MARIA ISABEL VERGARA SANCHEZ" },
    { value: "ANDRES FERNANDO CAICEDO ARIAS", label: "ANDRES FERNANDO CAICEDO ARIAS" },
    { value: "ALEXANDRA RAMOS COBO", label: "ALEXANDRA RAMOS COBO" },
    { value: "SYSADMINISTRADOR", label: "SYSADMINISTRADOR" },
    { value: "LEOPOLDINA AZCARATE ORREGO", label: "LEOPOLDINA AZCARATE ORREGO" },
    { value: "ELIZABETH MINA BRAND", label: "ELIZABETH MINA BRAND" },
    { value: "ERWIN JARAMILLO", label: "ERWIN JARAMILLO" },
    { value: "VICTOR JAVIER RENDON", label: "VICTOR JAVIER RENDON" },
    { value: "JENNY LEANDRA LEAL", label: "JENNY LEANDRA LEAL" },
    { value: "CARMEN ELENA FORERO", label: "CARMEN ELENA FORERO" },
    { value: "VICTOR HUGO REYES GALARZA", label: "VICTOR HUGO REYES GALARZA" },
    { value: "WILMOT DAVID RUANO IBARRA", label: "WILMOT DAVID RUANO IBARRA" },
    { value: "VICTOR FERNANDO ROJAS CAICEDO", label: "VICTOR FERNANDO ROJAS CAICEDO" },
    { value: "LUZ MARINA LOPEZ LEDESMA", label: "LUZ MARINA LOPEZ LEDESMA" },
    { value: "RUTH ELCIRA PELAEZ", label: "RUTH ELCIRA PELAEZ" },
    { value: "ALEXANDER CHAVARRIA JIMENEZ", label: "ALEXANDER CHAVARRIA JIMENEZ" },
    { value: "JORGE ALEXIS MENESES", label: "JORGE ALEXIS MENESES" },
    { value: "LUIS FERNANDO VIDAL MAFLA", label: "LUIS FERNANDO VIDAL MAFLA" },
    { value: "EDILMA ISABEL JIMENEZ AVILA", label: "EDILMA ISABEL JIMENEZ AVILA" },
    { value: "EDWARD SUAREZ FRANCO", label: "EDWARD SUAREZ FRANCO" },
    { value: "JUAN CARLOS GORDILLO VALENCIA", label: "JUAN CARLOS GORDILLO VALENCIA" },
    { value: "CARLOS ALBERTO CUERO", label: "CARLOS ALBERTO CUERO" },
    { value: "HOOVER STEVEN RUIZ", label: "HOOVER STEVEN RUIZ" },
    { value: "GLORIA PATRICIA OCAMPO", label: "GLORIA PATRICIA OCAMPO" },
    { value: "LUZ ANGELA CASTRILLON ZABALA", label: "LUZ ANGELA CASTRILLON ZABALA" },
    { value: "ANA CLEMENCIA LOEBEL REDING", label: "ANA CLEMENCIA LOEBEL REDING" },
    { value: "JENNY KATTERINE OLIVARES CORTES", label: "JENNY KATTERINE OLIVARES CORTES" },
    { value: "EDGAR FERNANDO FAJARDO FIGUEROA", label: "EDGAR FERNANDO FAJARDO FIGUEROA" },
    { value: "ERWIN ANDRES PAGUAY JARAMILLO", label: "ERWIN ANDRES PAGUAY JARAMILLO" },
    { value: "ERASMO CORREA RIASCOS", label: "ERASMO CORREA RIASCOS" },
    { value: "IRIS DAISSY RODRIGUEZ", label: "IRIS DAISSY RODRIGUEZ" },
    { value: "VANESSA OROZCO CASTILLO", label: "VANESSA OROZCO CASTILLO" },
    { value: "ALEJANDRA MONTES TAGARA", label: "ALEJANDRA MONTES TAGARA" },
    { value: "JORGE DAVID RAMIREZ TAFUR", label: "JORGE DAVID RAMIREZ TAFUR" },
    { value: "CLAUDIA MARIA ALVAREZ MUNOZ", label: "CLAUDIA MARIA ALVAREZ MUNOZ" },
    { value: "MARIA DEL PILAR RODRIGUEZ SOLIS", label: "MARIA DEL PILAR RODRIGUEZ SOLIS" },
    { value: "ALEXIS PERDOMO YOSA", label: "ALEXIS PERDOMO YOSA" },
    { value: "ARLEX FERNANDO OSORIO RIVERA", label: "ARLEX FERNANDO OSORIO RIVERA" },
    { value: "CLAUDIA LORENA CASTANO SANCHEZ", label: "CLAUDIA LORENA CASTANO SANCHEZ" },
    { value: "CARMENZA OBANDO CABEZAS", label: "CARMENZA OBANDO CABEZAS" },
    { value: "CARMEN ELENA ROSERO", label: "CARMEN ELENA ROSERO" },
    { value: "ARLEY MILLAN OSORIO", label: "ARLEY MILLAN OSORIO" },
    { value: "CONSTANZA SOLARTE RODRIGUEZ", label: "CONSTANZA SOLARTE RODRIGUEZ" },
    { value: "ELVIA MARIA GUTIERREZ DEVIA", label: "ELVIA MARIA GUTIERREZ DEVIA" },
    { value: "MONICA RODRIGUEZ DORADO", label: "MONICA RODRIGUEZ DORADO" },
    { value: "ANDREA ASTUDILLO RIVERA", label: "ANDREA ASTUDILLO RIVERA" },
    { value: "GLORIA AMPARO BONILLA GONZALEZ", label: "GLORIA AMPARO BONILLA GONZALEZ" },
    { value: "MARIA DEL PILAR CARABALI DONNEYS", label: "MARIA DEL PILAR CARABALI DONNEYS" },
    { value: "ALEXANDRA RAMOS COBO", label: "ALEXANDRA RAMOS COBO" },
    { value: "VIVIAN PATRICIA RENGIFO ESCOBAR", label: "VIVIAN PATRICIA RENGIFO ESCOBAR" },
    { value: "LUZ ADRIANA CRUZ AVILA", label: "LUZ ADRIANA CRUZ AVILA" },
    { value: "ALEXANDRA ALZATA ESPINAL", label: "ALEXANDRA ALZATA ESPINAL" },
    { value: "ALEJANDRO BISBICUTH MELO", label: "ALEJANDRO BISBICUTH MELO" },
    { value: "ALEXANDER CAMPO ARANA", label: "ALEXANDER CAMPO ARANA" },
    { value: "CINDY CATALINA OIDOR GARCIA", label: "CINDY CATALINA OIDOR GARCIA" },
    { value: "KAREN TATIANA MUNOZ BOLANOS", label: "KAREN TATIANA MUNOZ BOLANOS" },
    { value: "ALEIDA RUEDA ROMERO", label: "ALEIDA RUEDA ROMERO" },
    { value: "CLAUDIA LORENA CUAICAL CUESTA", label: "CLAUDIA LORENA CUAICAL CUESTA" },
    { value: "MARIA DEL PILAR MONTANO ROJO", label: "MARIA DEL PILAR MONTANO ROJO" },
    { value: "VICTOR MANUEL SERNA", label: "VICTOR MANUEL SERNA" },
    { value: "LUZ NATALIA ESPINOZA MOYA", label: "LUZ NATALIA ESPINOZA MOYA" },
    { value: "ALEX GABRIEL CASTANEDA VILLEGAS", label: "ALEX GABRIEL CASTANEDA VILLEGAS" },
    { value: "JHON EDWARD PARRA PRIETO", label: "JHON EDWARD PARRA PRIETO" },
    { value: "ANDRES GUERRERO LUNA", label: "ANDRES GUERRERO LUNA" },
    { value: "RAFAEL PAYAN MENDOZA", label: "RAFAEL PAYAN MENDOZA" },
    { value: "JENNIFER PAOLA HURTADO PRADO", label: "JENNIFER PAOLA HURTADO PRADO" },
    { value: "NHORA ELENA VACCA BARONA", label: "NHORA ELENA VACCA BARONA" },
    { value: "INGRID ANGELICA BARRETO VERA", label: "INGRID ANGELICA BARRETO VERA" },
    { value: "ALEJANDRO OROZCO URREA", label: "ALEJANDRO OROZCO URREA" },
    { value: "NELLY BUITRAGO SANCHEZ", label: "NELLY BUITRAGO SANCHEZ" },
    { value: "LILIANA RODRIGUEZ RENGIFO", label: "LILIANA RODRIGUEZ RENGIFO" },
    { value: "NESTOR FABIAN ROJAS", label: "NESTOR FABIAN ROJAS" },
    { value: "CARMEN ELENA POSSO POLO", label: "CARMEN ELENA POSSO POLO" }
  ],
  groupOptions: [
    { value: "GENERALES RECIBIDO", label: "GENERALES - RECIBIDO" },
    { value: "GENERALES ENVIADO", label: "GENERALES - ENVIADO" },
    { value: "GENERALES INTERNO", label: "GENERALES - INTERNO" },
    { value: "DERECHOS DE PETICION", label: "DERECHOS DE PETICIÓN" },
    { value: "ACCIONES DE TUTELA", label: "ACCIONES DE TUTELA" },
    { value: "RECURSOS", label: "RECURSOS" },
    { value: "OTROS", label: "OTROS" },
    { value: "PQRSDF", label: "PQRSDF" },
    { value: "ACCIONES DE CUMPLIMIENTO", label: "ACCIONES DE CUMPLIMIENTO" },
    { value: "CONCILIACIONES", label: "CONCILIACIONES" },
    { value: "PROCESOS EJECUTIVOS", label: "PROCESOS EJECUTIVOS" },
    { value: "TRAMITES", label: "TRÁMITES" },
    { value: "PRODUCTOS", label: "PRODUCTOS" },
    { value: "OTROS PQRS", label: "OTROS PQRS" },
    { value: "SOPORTES BANCOS", label: "SOPORTES BANCOS" },
    { value: "DECRETOS Y ACUERDOS MUNICIPALES Y DISTRITALES", label: "DECRETOS Y ACUERDOS MUNICIPALES Y DISTRITALES" },
    { value: "PASIVO PENSIONAL", label: "PASIVO PENSIONAL" },
    { value: "ACCIONES JUDICIALES", label: "ACCIONES JUDICIALES" },
    { value: "TRAMITES RENTAS", label: "TRÁMITES RENTAS" },
    { value: "PROCESO TRIBUTARIO", label: "PROCESO TRIBUTARIO" },
    { value: "NOTIFICACION DE LA TUTELA", label: "NOTIFICACION DE LA TUTELA" },
    { value: "ACCIONES POPULARES", label: "ACCIONES POPULARES" },
    { value: "TARJ DE INVITACION", label: "TARJ. DE INVITACIÓN" },
    { value: "PROCESOS JUDICIALES", label: "PROCESOS JUDICIALES" },
    { value: "OFERTAS Y COTIZAC", label: "OFERTAS Y COTIZAC." },
    { value: "COMUNICAC OFICIALES", label: "COMUNICAC. OFICIALES" },
    { value: "TELEGRAMA", label: "TELEGRAMA" },
    { value: "FORMATOS", label: "FORMATOS" },
    { value: "HOJA DE VIDA", label: "HOJA DE VIDA" },
    { value: "CARPETA", label: "CARPETA" },
    { value: "CIRCULARES", label: "CIRCULARES" },
    { value: "INTERNA", label: "INTERNA" },
    { value: "DECRETOS", label: "DECRETOS" },
    { value: "RESOLUCIONES", label: "RESOLUCIONES" },
    { value: "CONTRATOS", label: "CONTRATOS" },
    { value: "CONVENIOS", label: "CONVENIOS" },
    { value: "AUTOS", label: "AUTOS" },
    { value: "ACTAS", label: "ACTAS" },
    { value: "ACUERDOS CONSEJOS", label: "ACUERDOS CONSEJOS" },
    { value: "TUTELAS", label: "TUTELAS" },
    { value: "QUEJAS", label: "QUEJAS" },
    { value: "INCIDENTE DE DESACATO", label: "INCIDENTE DE DESACATO" },
    { value: "AUDIENCIAS DE CONCILIACION", label: "AUDIENCIAS DE CONCILIACION" },
    { value: "CITACION", label: "CITACION" },
    { value: "NULIDAD Y RESTABLECIMIENTO DEL DERECHO", label: "NULIDAD Y RESTABLECIMIENTO DEL DERECHO" },
    { value: "CERTIFICACION", label: "CERTIFICACION" },
    { value: "INFORMATIVA", label: "INFORMATIVA" },
    { value: "INFORMES", label: "INFORMES" },
    { value: "REQUERIMIENTO DE INFORMACION", label: "REQUERIMIENTO DE INFORMACIÓN" },
    { value: "PLIEGO DE CARGOS", label: "PLIEGO DE CARGOS" },
    { value: "AUTO DE CIERRE Y ARCHIVO", label: "AUTO DE CIERRE Y ARCHIVO" },
    { value: "EMPLAZAMIENTO PARA DECLARAR", label: "EMPLAZAMIENTO PARA DECLARAR" },
    { value: "EMPLAZAMIENTO PARA CORREGIR", label: "EMPLAZAMIENTO PARA CORREGIR" },
    { value: "REQUERIMIENTO ESPECIAL", label: "REQUERIMIENTO ESPECIAL" },
    { value: "AMPLIACION A REQUERIMIENTO ESPECIAL", label: "AMPLIACIÓN A REQUERIMIENTO ESPECIAL" },
    { value: "ANONIMOS", label: "ANONIMOS" },
    { value: "INTERES PARTICULAR O GENERAL", label: "INTERÉS PARTICULAR O GENERAL" },
    { value: "SOLICITUD DE INFORMACION Y/O DOCUMENTOS", label: "SOLICITUD DE INFORMACIÓN Y/O DOCUMENTOS" },
    { value: "CONSULTA O CONCEPTO", label: "CONSULTA O CONCEPTO" },
    { value: "PETICION DE TERMINO ESPECIAL", label: "PETICIÓN DE TÉRMINO ESPECIAL" },
    { value: "QUEJA", label: "QUEJA" },
    { value: "RECLAMO", label: "RECLAMO" },
    { value: "SUGERENCIA", label: "SUGERENCIA" },
    { value: "DENUNCIA", label: "DENUNCIA" },
    { value: "RECURSO DE RECONSIDERACION", label: "RECURSO DE RECONSIDERACION" },
    { value: "RECURSO DE REPOSICION", label: "RECURSO DE REPOSICION" },
    { value: "EXCEPCION CONTRA MANDAMIENTO DE PAGO", label: "EXCEPCION CONTRA MANDAMIENTO DE PAGO" },
    { value: "RECURSO DE APELACION", label: "RECURSO DE APELACION" },
    { value: "RESPUESTA", label: "RESPUESTA" },
    { value: "COMUNICACION INFORMATIVA", label: "COMUNICACION INFORMATIVA" },
    { value: "OFERTAS Y COTIZACIONES", label: "OFERTAS Y COTIZACIONES" },
    { value: "REMISION DE DOCUMENTOS", label: "REMISION DE DOCUMENTOS" },
    { value: "INVITACION", label: "INVITACION" },
    { value: "NOTIFICACION DE LA DEMANDA", label: "NOTIFICACION DE LA DEMANDA" },
    { value: "RESPUESTA DE INTERPOSICION ACCION DE TUTELA", label: "RESPUESTA DE INTERPOSICION ACCION DE TUTELA" },
    { value: "NOTIFICACION FALLO DE PRIMERA INSTANCIA", label: "NOTIFICACION FALLO DE PRIMERA INSTANCIA" },
    { value: "IMPUGNACION", label: "IMPUGNACION" },
    { value: "NOTIFICACION FALLO DE SEGUNDA INSTANCIA", label: "NOTIFICACION FALLO DE SEGUNDA INSTANCIA" },
    { value: "DESACATO", label: "DESACATO" },
    { value: "PQRS QUEJAS", label: "PQRS-QUEJAS" },
    { value: "PQRS RECLAMOS", label: "PQRS-RECLAMOS" },
    { value: "PQRS PETICION ENTRE AUTORIDADES", label: "PQRS-PETICIÓN ENTRE AUTORIDADES" },
    { value: "PQRS RECURSO", label: "PQRS-RECURSO" },
    { value: "PQRS FELICITACION", label: "PQRS-FELICITACION" },
    { value: "PQRS SOLICITUD DE LA OPOSICION", label: "PQRS-SOLICITUD DE LA OPOSICIÓN" },
    { value: "PQRS DENUNCIAS", label: "PQRS-DENUNCIAS" },
    { value: "PQRS SOLICITUD DEL CONGRESO", label: "PQRS-SOLICITUD DEL CONGRESO" },
    { value: "PQRS PETICION DE INTERES PARTICULAR O GENERAL", label: "PQRS-PETICION DE INTERES PARTICULAR O GENERAL" },
    { value: "PQRS PETICION DE DOCUMENTOS DE INTERES PUBLICO", label: "PQRS-PETICION DE DOCUMENTOS DE INTERES PUBLICO" },
    { value: "PROYECTOS EN CURSO", label: "PROYECTOS EN CURSO" },
    { value: "PQRS SUGERENCIAS", label: "PQRS-SUGERENCIAS" },
    { value: "PQRS CONSULTA", label: "PQRS-CONSULTA" },
    { value: "NOTIFICACION", label: "NOTIFICACIÓN" },
    { value: "FALLO PRIMERA INSTANCIA", label: "FALLO PRIMERA INSTANCIA" },
    { value: "FALLO SEGUNDA INSTANCIA", label: "FALLO SEGUNDA INSTANCIA" },
    { value: "RECURSO DE IMPUGNACION", label: "RECURSO DE IMPUGNACIÓN" },
    { value: "JUDICIALES", label: "JUDICIALES" },
    { value: "EXTRAJUDICIALES", label: "EXTRAJUDICIALES" },
    { value: "NOTIFICACION DE MANDAMIENTO DE PAGO", label: "NOTIFICACIÓN DE MANDAMIENTO DE PAGO" },
    { value: "RECURSO DE QUEJA", label: "RECURSO DE QUEJA" },
    { value: "FALLO", label: "FALLO" },
    { value: "CAMBIO PROPIETARIO POSEEDOR U OCUPANTE", label: "CAMBIO PROPIETARIO POSEEDOR U OCUPANTE" },
    { value: "AUTOESTIMACION DE AVALUO CATASTRAL", label: "AUTOESTIMACION DE AVALUO CATASTRAL" },
    { value: "ENGLOBE Y DESENGLOBE", label: "ENGLOBE Y DESENGLOBE" },
    { value: "INCORPORACION REFORMAS ADICIONES Y ACLARACIONES DE PROPIEDAD HORIZONTAL", label: "INCORPORACION, REFORMAS, ADICIONES Y ACLARACIONES DE PROPIEDAD HORIZONTAL" },
    { value: "INCORPORACIONES DE MEJORAS O PREDIOS OMITIDOS", label: "INCORPORACIONES DE MEJORAS O PREDIOS OMITIDOS" },
    { value: "INCORPORACION DE CONSTRUCCION Y/O CAMBIOS EN EL DESTINO ECONOMICO", label: "INCORPORACION DE CONSTRUCCION Y/O CAMBIOS EN EL DESTINO ECONOMICO" },
    { value: "CORRECCION DE FORMACION Y AREA CATASTRAL", label: "CORRECCION DE FORMACION Y AREA CATASTRAL" },
    { value: "RECTIFICACION O COMPLEMENTACION DE LA INFORMACION CATASTRAL", label: "RECTIFICACIÓN O COMPLEMENTACIÓN DE LA INFORMACIÓN CATASTRAL" },
    { value: "REVISION DEL AVALUO CATASTRAL", label: "REVISIÓN DEL AVALÚO CATASTRAL" },
    { value: "VALOR POR CERTIFICACION SIN MEDIACION TECNICA", label: "VALOR POR CERTIFICACIÓN SIN MEDIACIÓN TÉCNICA" },
    { value: "VALOR POR CERTIFICACION CON MEDIACION TECNICA", label: "VALOR POR CERTIFICACIÓN CON MEDIACIÓN TÉCNICA" },
    { value: "ACTUALIZACION DE LINDEROS", label: "ACTUALIZACIÓN DE LINDEROS" },
    { value: "RECTIFICACION DE AREA POR IMPRECISA DETERMINACION", label: "RECTIFICACIÓN DE ÁREA POR IMPRECISA DETERMINACIÓN" },
    { value: "RECTIFICACION DE LINDEROS POR ACUERDO ENTRE LAS PARTES", label: "RECTIFICACIÓN DE LINDEROS POR ACUERDO ENTRE LAS PARTES" },
    { value: "CERTIFICADO PLANO PREDIAL CATASTRAL CPPC", label: "CERTIFICADO PLANO PREDIAL CATASTRAL (CPPC)" },
    { value: "CERTIFICADO CATASTRAL ESPECIAL", label: "CERTIFICADO CATASTRAL ESPECIAL" },
    { value: "CERTIFICADO AVALUO PARA PROYECTO DE INFRAESTRUCTURA", label: "CERTIFICADO AVALÚO PARA PROYECTO DE INFRAESTRUCTURA" },
    { value: "CERTIFICADO AVALUO CATASTRAL", label: "CERTIFICADO AVALÚO CATASTRAL" },
    { value: "CERTIFICADO DE PROPIEDAD O POSESION DE BIENES PERSONA JURIDICA O NATURAL", label: "CERTIFICADO DE PROPIEDAD O POSESIÓN DE BIENES PERSONA JURÍDICA O NATU" },
    { value: "FICHA PREDIAL COMPLETA ANALOGA O DIGITAL", label: "FICHA PREDIAL COMPLETA ANÁLOGA O DIGITAL" },
    { value: "CROQUIS DEL PREDIO", label: "CROQUIS DEL PREDIO" },
    { value: "CARTA CATASTRAL URBANA POR PLANCHA", label: "CARTA CATASTRAL URBANA (POR PLANCHA)" },
    { value: "CARTA CATASTRAL RURAL POR PLANCHA", label: "CARTA CATASTRAL RURAL (POR PLANCHA)" },
    { value: "FORMATO DE 1 A 150 REGISTROS POR MUNICIPIO", label: "FORMATO DE 1 A 150 REGISTROS POR MUNICIPIO" },
    { value: "REGISTROS BASE CATASTRAL ALFANUMERICA MAYOR A 150 REGISTROS", label: "REGISTROS BASE CATASTRAL ALFANUMÉRICA ( MAYOR A 150 REGISTROS)" },
    { value: "REPORTE DE FORMULARIO BANCARIO", label: "REPORTE DE FORMULARIO BANCARIO" },
    { value: "EXTRACTOS Y RECAUDOS", label: "EXTRACTOS Y RECAUDOS" },
    { value: "CERTIFICACIONES ESAL SALUD", label: "CERTIFICACIONES ESAL-SALUD" },
    { value: "CERTIFICACIONES DAJ", label: "CERTIFICACIONES DAJ" },
    { value: "PLANOS CON INFORMACION CATASTRAL A DIFERENTES ESCALAS", label: "PLANOS CON INFORMACIÓN CATASTRAL A DIFERENTES ESCALAS" },
    { value: "ESTADISTICAS CATASTRALES DIGITALES Y/O IMPRESAS", label: "ESTADÍSTICAS CATASTRALES DIGITALES Y/O IMPRESAS" },
    { value: "INFORMACION CARTOGRAFICA CATASTRAL", label: "INFORMACION CARTOGRAFICA CATASTRAL" },
    { value: "INFORMACION DE ZONAS HOMOGENEAS FISICAS Y GEOECONOMICAS URBANAS", label: "INFORMACION DE ZONAS HOMOGENEAS FISICAS Y GEOECONOMICAS URBANAS" },
    { value: "INFORMACION DE PLANOS DE CONJUNTO URBANO", label: "INFORMACION DE PLANOS DE CONJUNTO URBANO" },
    { value: "INFORMACION RURAL DE ZONAS GEOECONOMICAS", label: "INFORMACION RURAL DE ZONAS GEOECONOMICAS" },
    { value: "PLOTEOS DE INFORMACION CATASTRAL URBANA O RURAL", label: "PLOTEOS DE INFORMACION CATASTRAL URBANA O RURAL" },
    { value: "CARTOGRAFIA BASICA Y CATASTRAL POR MUNICIPIO", label: "CARTOGRAFIA BASICA Y CATASTRAL POR MUNICIPIO" },
    { value: "AEROFOTOGRAFIA DIGITAL CON CAMARA ANALOGA", label: "AEROFOTOGRAFIA DIGITAL CON CAMARA ANALOGA" },
    { value: "MODELO DIGITAL DEL TERRENO", label: "MODELO DIGITAL DEL TERRENO" },
    { value: "ESPACIOMAPAS", label: "ESPACIOMAPAS" },
    { value: "ORTOFOTOMOSAICOS", label: "ORTOFOTOMOSAICOS" },
    { value: "AEROFOTOGRAFIA IMPRESA", label: "AEROFOTOGRAFIA IMPRESA" },
    { value: "ESTUDIOS MULTITEMPORALES", label: "ESTUDIOS MULTITEMPORALES" },
    { value: "AVALUOS COMERCIALES", label: "AVALUOS COMERCIALES" },
    { value: "INCORPORACION DE MEJORA EN TERRENO AJENO", label: "INCORPORACION DE MEJORA EN TERRENO AJENO" },
    { value: "TRAMITES CON EFECTOS REGISTRALES", label: "TRAMITES CON EFECTOS REGISTRALES" },
    { value: "RECTIFICACION O COMPLEMENTACION CATASTRAL IMPACTO CARTOGRAFICO", label: "RECTIFICACION O COMPLEMENTACION CATASTRAL IMPACTO CARTOGRAFICO" },
    { value: "ACUERDOS MUNICIPALES", label: "ACUERDOS MUNICIPALES" },
    { value: "ACUERDOS DISTRITALES", label: "ACUERDOS DISTRITALES" },
    { value: "BONOS PENSIONALES", label: "BONOS PENSIONALES" },
    { value: "HISTORIAS LABORALES", label: "HISTORIAS LABORALES" },
    { value: "AUXILIOS FUNERARIOS", label: "AUXILIOS FUNERARIOS" },
    { value: "PRIMAS DE ANTIGUEDAD", label: "PRIMAS DE ANTIGUEDAD" },
    { value: "AUXILIO DE MATERNIDAD", label: "AUXILIO DE MATERNIDAD" },
    { value: "INDEMNIZACION SUSTITUTIVA", label: "IDEMNIZACION SUSTITUTIVA" },
    { value: "SUSTITUCION PERSONAL", label: "SUSTITUCION PERSONAL" },
    { value: "DEVOLUCION Y/O COMPENSACION PAGOS EXCESO O NO DEBIDO", label: "DEVOLUCION Y/O COMPENSACION PAGOS EXCESO O NO DEBIDO" },
    { value: "LEGALIZACION TORNAGUIAS", label: "LEGALIZACION TORNAGUIAS" },
    { value: "ANULACION DE TORNAGUIAS", label: "ANULACION DE TORNAGUIAS" },
    { value: "SOLICITUD TORNAGUIAS", label: "SOLICITUD TORNAGUIAS" },
    { value: "EXENCION DE IMPUESTO REGISTRO VIVIENDA DE INTERES SOCIAL", label: "EXENCION DE IMPUESTO REGISTRO VIVIENDA DE INTERES SOCIAL" },
    { value: "DECLARACION DE IMPUESTO AL CONSUMO", label: "DECLARACION DE IMPUESTO AL CONSUMO" },
    { value: "SOLICITUD TASA ESPECIAL SEGURIDAD Y CONVIVENICA", label: "SOLICITUD TASA ESPECIAL SEGURIDAD Y CONVIVENICA" },
    { value: "FACILIDADES DE PAGO PARA DEUDORES", label: "FACILIDADES DE PAGO PARA DEUDORES" },
    { value: "LEVANTAMIENTO GRAVAMEN VALORIZACION DEP", label: "LEVANTAMIENTO GRAVAMEN VALORIZACION DEP" },
    { value: "EXENCION DE IMPUESTOS SOBRE VEHICULOS AUTOMOTORES", label: "EXENCION DE IMPUESTOS SOBRE VEHICULOS AUTOMOTORES" },
    { value: "INSCRIPCION ADICION Y NOVEDADES REGISTRO CONTRIBUYENTES", label: "INSCRIPCION-ADICION Y NOVEDADES REGISTRO CONTRIBUYENTES" },
    { value: "DECLARACIONES TRIBUTARIAS", label: "DECLARACIONES TRIBUTARIAS" },
    { value: "RECURSO REPOSICION EN CONTRA DE EXCEPCIONES", label: "RECURSO REPOSICION EN CONTRA DE EXCEPCIONES" },
    { value: "SOLICITUD REVOCATORIA DIRECTA", label: "SOLICITUD REVOCATORIA DIRECTA" },
    { value: "SOLICITUD REVOCATORIA DIRECTA GENERAL", label: "SOLICITUD REVOCATORIA DIRECTA GENERAL" },
    { value: "RESPUESTA DE EMPLAZAMIENTO PARA DECLARAR", label: "RESPUESTA DE EMPLAZAMIENTO PARA DECLARAR" },
    { value: "RESPUESTA DE EMPLAZAMIENTO PARA CORREGIR", label: "RESPUESTA DE EMPLAZAMIENTO PARA CORREGIR" },
    { value: "SOLICITUD FRACCIONAMIENTO", label: "SOLICITUD FRACCIONAMIENTO" },
    { value: "OPOSICION AL SECUESTRO", label: "OPOSICION AL SECUESTRO" },
    { value: "RESPUESTA PLIEGOS DE CARGO", label: "RESPUESTA PLIEGOS DE CARGO" },
    { value: "SOLICITUD DEVOLUCION TITULOS DEPOSITO JUDICIAL", label: "SOLICITUD DEVOLUCION TITULOS DEPOSITO JUDICIAL" },
    { value: "SOLICITUD LEVANTAMIENTO MEDIDAS CAUTELARES", label: "SOLICITUD LEVANTAMIENTO MEDIDAS CAUTELARES" },
    { value: "RESPUESTA A REQUERIMIENTOS DE INFORMACION", label: "RESPUESTA A REQUERIMIENTOS DE INFORMACION" },
    { value: "RESPUESTA OFICIOS PERSUASIVOS", label: "RESPUESTA OFICIOS PERSUASIVOS" },
    { value: "RESPUESTA AUTOS INADMISORIOS", label: "RESPUESTA AUTOS INADMISORIOS" },
  ],
  employeesByDependence : {
    "ALTA CONSEJERIA PARA LA PAZ": [
      { value: "CAMILA AMAYA", label: "CAMILA AMAYA", email: "camila@mantisapp.co" },
      { value: "FRANCISCO ALBERTO PEREZ PERDOMO", label: "FRANCISCO ALBERTO PEREZ PERDOMO"},
      { value: "GENER ANTONIO BUITRAGO BARBETTI", label: "GENER ANTONIO BUITRAGO BARBETTI" },
      { value: "MARIAN ESTEPHANIE MUNOZ", label: "MARIAN ESTEPHANIE MUNOZ" }
    ],
    "ALTA CONSEJERIA PARA MORALIDAD ANTICORRUPCION": [
      { value: "JOSE DAVID BONILLA MENESES", label: "JOSE DAVID BONILLA MENESES" },
      { value: "KATHERINE HERRERA GARCES", label: "KATHERINE HERRERA GARCES" },
      { value: "MONICA HENAO", label: "MONICA HENAO" }
    ],
    "CONTADURIA GENERAL DEL DEPARTAMENTO": [
      { value: "MARIA CLAUDIA SALINAS CASTILLO", label: "MARIA CLAUDIA SALINAS CASTILLO" }
    ],
    "DEPARTAMENTO ADMINISTRATIVO DE DESARROLLO INSTITUCIONAL": [
      { value: "ADRIANA PERLAZA", label: "ADRIANA PERLAZA" },
      { value: "ALEJANDRA SAABEDRA CASTRILLON", label: "ALEJANDRA SAABEDRA CASTRILLON" },
      { value: "ALEJANDRO OROZCO URREA", label: "ALEJANDRO OROZCO URREA" },
      { value: "ARMANDO MORENO ZUNIGA", label: "ARMANDO MORENO ZUNIGA" },
      { value: "BLANCA INES VALENCIA GUTIERREZ", label: "BLANCA INES VALENCIA GUTIERREZ" },
      { value: "CARMEN ELISA SALAZAR SIERRA", label: "CARMEN ELISA SALAZAR SIERRA" },
      { value: "CAROL TATIANA DIAZ CAMACHO", label: "CAROL TATIANA DIAZ CAMACHO" },
      { value: "DARLY ALEJANDRA ENCINALES", label: "DARLY ALEJANDRA ENCINALES" },
      { value: "DIANA MARCELA IZQUIERDO", label: "DIANA MARCELA IZQUIERDO" },
      { value: "DOLLY CECILIA CASTILLO SANDOVAL", label: "DOLLY CECILIA CASTILLO SANDOVAL" },
      { value: "DORA RUIZ AGUADO", label: "DORA RUIZ AGUADO" },
      { value: "ELMER LEANDRO ORDONEZ ARTUNDUAGA", label: "ELMER LEANDRO ORDONEZ ARTUNDUAGA" },
      { value: "GLADYS SALAZAR VILLEGAS", label: "GLADYS SALAZAR VILLEGAS" },
      { value: "GLORIA AMPARO BONILLA GONZALEZ", label: "GLORIA AMPARO BONILLA GONZALEZ" },
      { value: "GLORIA NANCY LOPEZ BARCO", label: "GLORIA NANCY LOPEZ BARCO" },
      { value: "IBETH RESTREPO ESPITIA", label: "IBETH RESTREPO ESPITIA" },
      { value: "ISABELA LEAL FRANCO", label: "ISABELA LEAL FRANCO" },
      { value: "JAKELINE VELEZ PEREZ", label: "JAKELINE VELEZ PEREZ" },
      { value: "JANETH VIVIANA DE LA TORRE MARTINEZ", label: "JANETH VIVIANA DE LA TORRE MARTINEZ" },
      { value: "JENNY KATTERINE OLIVARES CORTES", label: "JENNY KATTERINE OLIVARES CORTES" },
      { value: "JESSICA HERNANDEZ GOMEZ", label: "JESSICA HERNANDEZ GOMEZ" },
      { value: "JOHNATAN REYES ROSERO", label: "JOHNATAN REYES ROSERO" },
      { value: "JORGE CASANOVA", label: "JORGE CASANOVA" },
      { value: "JOSE BLADIMIR DELGADO SOSSA", label: "JOSE BLADIMIR DELGADO SOSSA" },
      { value: "LAURA ALEJANDRA GUEVARA MARTINEZ", label: "LAURA ALEJANDRA GUEVARA MARTINEZ" },
      { value: "LUIS FERNANDO VIDAL MAFLA", label: "LUIS FERNANDO VIDAL MAFLA" },
      { value: "LUZ HELENA SAAVEDRA DURAN", label: "LUZ HELENA SAAVEDRA DURAN" },
      { value: "MAIK ALEJANDRO GALINDEZ CASTANO", label: "MAIK ALEJANDRO GALINDEZ CASTANO" },
      { value: "MAIQUEL USURRIAGA UZURRIAGA", label: "MAIQUEL USURRIAGA UZURRIAGA" },
      { value: "MARIA ALEJANDRA BOADA RODRIGUEZ", label: "MARIA ALEJANDRA BOADA RODRIGUEZ" },
      { value: "MARIA DORIS PAZ CHAVEZ", label: "MARIA DORIS PAZ CHAVEZ" },
      { value: "MARTHA CECILIA BALCAZAR LOPEZ", label: "MARTHA CECILIA BALCAZAR LOPEZ" },
      { value: "MARVIN ORTIZ CARVAJAL", label: "MARVIN ORTIZ CARVAJAL" },
      { value: "MILDRE IVON BALANTA", label: "MILDRE IVON BALANTA" },
      { value: "NORMAN SANIN QUIROZ", label: "NORMAN SANIN QUIROZ" },
      { value: "OLGA LUCIA ORTIZ OCORO", label: "OLGA LUCIA ORTIZ OCORO" },
      { value: "OLGA NELLY FLOREZ DIAZ", label: "OLGA NELLY FLOREZ DIAZ" },
      { value: "OMAR FELIPE BOTERO LARA", label: "OMAR FELIPE BOTERO LARA" },
      { value: "OSCAR BEDOYA", label: "OSCAR BEDOYA" },
      { value: "PAOLA ANDREA CABRERA VALLEJO", label: "PAOLA ANDREA CABRERA VALLEJO" },
      { value: "SAUL HERNANDEZ", label: "SAUL HERNANDEZ" },
      { value: "YENNY CAROLINA GALVEZ", label: "YENNY CAROLINA GALVEZ" }
    ],
    "DEPARTAMENTO ADMINISTRATIVO DE JURIDICA": [
      { value: "AIDA LUZ MARINA CADENA RIASCOS", label: "AIDA LUZ MARINA CADENA RIASCOS" },
      { value: "ANLLY CAROLINA RIASCOS MORENO", label: "ANLLY CAROLINA RIASCOS MORENO" },
      { value: "BETSY CAROLINA RODRIGUEZ MONTENEGRO", label: "BETSY CAROLINA RODRIGUEZ MONTENEGRO" },
      { value: "CLAUDIA LORENA CUAICAL CUESTA", label: "CLAUDIA LORENA CUAICAL CUESTA" },
      { value: "DANNY MICHEL RAMOS PEREA", label: "DANNY MICHEL RAMOS PEREA" },
      { value: "FABIAN PENARANDA", label: "FABIAN PENARANDA" },
      { value: "GLORIA PATRICIA OCAMPO", label: "GLORIA PATRICIA OCAMPO" },
      { value: "JUAN JOSE CASTRO MILLAN", label: "JUAN JOSE CASTRO MILLAN" },
      { value: "JULIAN ABADIA BASTIDAS", label: "JULIAN ABADIA BASTIDAS" },
      { value: "LAURA CAMILA GARZON SILVA", label: "LAURA CAMILA GARZON SILVA" },
      { value: "MARIA ALEJANDRA ACOSTA NARVAEZ", label: "MARIA ALEJANDRA ACOSTA NARVAEZ" },
      { value: "MARIA CEYDE OCAMPO GONZALEZ", label: "MARIA CEYDE OCAMPO GONZALEZ" },
      { value: "SILVIA LOPEZ ARANA", label: "SILVIA LOPEZ ARANA" }
    ],
    "DEPARTAMENTO ADMINISTRATIVO DE PLANEACIÓN": [
      { value: "EDWIN ANDRES AYA MORALES", label: "EDWIN ANDRES AYA MORALES" }
    ],
  },
  dependenceOptions: [
    { value: "", disabled: true, label: "Seleccione una dependencia"},
    { value: "ALTA CONSEJERIA PARA LA PAZ", label: "ALTA CONSEJERIA PARA LA PAZ" },
    { value: "DESPACHO DEL GOBERNADOR", label: "DESPACHO DEL GOBERNADOR" },
    { value: "SECRETARIA DE LAS TIC", label: "SECRETARÍA DE LAS TIC" },
    { value: "OFICINA CASA DEL VALLE EN BOGOTA", label: "OFICINA CASA DEL VALLE EN BOGOTA" },
    { value: "SECRETARIA GENERAL", label: "SECRETARIA GENERAL" },
    { value: "DEPTO ADMINISTRATIVO DE PLANEACION", label: "DEPTO ADMINISTRATIVO DE PLANEACION" },
    { value: "DEPTO ADMINISTRATIVO JURIDICO", label: "DEPTO ADMINISTRATIVO JURIDICO" },
    { value: "OFICINA DE CONTROL INTERNO", label: "OFICINA DE CONTROL INTERNO" },
    { value: "SEC DE INFRAESTRUCTURA Y VALORIZACION", label: "SEC DE INFRAESTRUCTURA Y VALORIZACION" },
    { value: "SEC DE AMBIENTE AGRICULTURA Y PESCA", label: "SEC DE AMBIENTE, AGRICULTURA Y PESCA" },
    { value: "SECRETARIA DE EDUCACION", label: "SECRETARIA DE EDUCACION" },
    { value: "SECRETARIA DE SALUD", label: "SECRETARIA DE SALUD" },
    { value: "SEC DE DESARROLLO SOCIAL Y PARTICIPACION", label: "SEC DE DESARROLLO SOCIAL Y PARTICIPACION" },
    { value: "SEC DE CULTURA", label: "SEC DE CULTURA" },
    { value: "SEC DE VIVIENDA Y HABITAT", label: "SEC DE VIVIENDA Y HABITAT" },
    { value: "SECRETARIA DE GOBIERNO", label: "SECRETARIA DE GOBIERNO" },
    { value: "SECRETARIA DE HACIENDA Y FINANZAS PUBLICAS", label: "SECRETARIA DE HACIENDA Y FINANZAS PUBLICAS" },
    { value: "DPTO ADMINISTRATIVO DE DESARROLLO INSTITUCIONAL", label: "DPTO ADMINISTRATIVO DE DESARROLLO INSTITUCIONAL" },
    { value: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO", label: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO" },
    { value: "OFICNA DE COMUNICACIONES SECRETARIA GENERAL", label: "OFICNA DE COMUNICACIONES SECRETARIA GENERAL" },
    { value: "SUBSECRETARIO GENERAL", label: "SUBSECRETARIO GENERAL" },
    { value: "SUBSECRETARIA DE ASISTENCIA TECNICA", label: "SUBSECRETARIA DE ASISTENCIA TECNICA" },
    { value: "SEC DE MOVILIDAD Y TRANSPORTE", label: "SEC DE MOVILIDAD Y TRANSPORTE" },
    { value: "SUBSECRETARIA DE MACROPROYECTOS", label: "SUBSECRETARIA DE MACROPROYECTOS" },
    { value: "DIRECCION TECNICA DE SEGURIDAD ALIMENTARIA", label: "DIRECCION TECNICA DE SEGURIDAD ALIMENTARIA" },
    { value: "SUBSECRETARIA DE ASUNTO AGROPECUARIOS Y PESCA", label: "SUBSECRETARIA DE ASUNTO AGROPECUARIOS Y PESCA" },
    { value: "DIRECCION TECNICA PLANEACION", label: "DIRECCIÓN TÉCNICA PLANEACION" },
    { value: "SUBDIRECCION DE ESTUDIOS SOCIOECONO Y COMPETITIVI", label: "SUBDIRECCION DE ESTUDIOS SOCIOECONO Y COMPETITIVI" },
    { value: "SUBDIRECCION DE INVERSION PUBLICA", label: "SUBDIRECCION DE INVERSION PUBLICA" },
    { value: "SUBDIRECCION DE ASISTENCIA A LA GESTION ENTES TERR", label: "SUBDIRECCION DE ASISTENCIA A LA GESTION ENTES TERR" },
    { value: "SUBSECRETARIA ESTUDIOS SOCIO ECONOMICOS", label: "SUBSECRETARIA ESTUDIOS SOCIO-ECONOMICOS" },
    { value: "DIRECCION PARA LA PARTICIPACION SOCIAL Y EL USUARI", label: "DIRECCION PARA LA PARTICIPACION SOCIAL Y EL USUARI" },
    { value: "SUBSECRETARIA DE SALUD PUBLICA", label: "SUBSECRETARIA DE SALUD PUBLICA" },
    { value: "SUBSECRETARIA DE GESTION INTEGRADA DE RECURSOS", label: "SUBSECRETARIA DE GESTION INTEGRADA DE RECURSOS" },
    { value: "OFICINA DE APOYO AL DESPACHO", label: "OFICINA DE APOYO AL DESPACHO" },
    { value: "OFICINA PLANEACION EDUCACION", label: "OFICINA PLANEACION EDUCACION" },
    { value: "OFICINA JURIDICA EDUCACION", label: "OFICINA JURIDICA EDUCACION" },
    { value: "DIRECCCION TECNICA", label: "DIRECCCION TECNICA" },
    { value: "SUBSECRETARIA DE FOMENTO EMPRESARIAL", label: "SUBSECRETARIA DE FOMENTO EMPRESARIAL" },
    { value: "SUBSECRETARIA DESARROLLO PROGRAMAS SOC Y PARTIC", label: "SUBSECRETARIA DESARROLLO PROGRAMAS SOC. Y PARTIC" },
    { value: "DIRECCION TECNICO DE TURISMO", label: "DIRECCION TECNICO DE TURISMO" },
    { value: "SUBSECRETARIA DE VIVIENDA Y HABITAT", label: "SUBSECRETARIA DE VIVIENDA Y HABITAT" },
    { value: "DIRECCION TECNICA GOBIERNO", label: "DIRECCION TECNICA GOBIERNO" },
    { value: "SUBSECRETARIA DE CONVIVENCIA Y SEGURIDAD", label: "SUBSECRETARIA DE CONVIVENCIA Y SEGURIDAD" },
    { value: "SUBSECRETARIA DE ASUNTOS DELEGADOS", label: "SUBSECRETARIA DE ASUNTOS DELEGADOS" },
    { value: "SEC DE GESTION DEL RIESGO DE DESASTRES", label: "SEC DE GESTION DEL RIESGO DE DESASTRES" },
    { value: "DIRECCION TECNICA HACIENDA", label: "DIRECCION TECNICA HACIENDA" },
    { value: "SUB PRESUPUESTO Y FINANZAS PUBLICAS", label: "SUB PRESUPUESTO Y FINANZAS PUBLICAS" },
    { value: "SUB DE CONTADURIA", label: "SUB DE CONTADURIA" },
    { value: "SUBSECRETARIA DE ADMINISTRACION IMPUESTOS Y RENTAS", label: "SUBSECRETARIA DE ADMINISTRACION IMPUESTOS Y RENTAS" },
    { value: "SUB DE TESORERIA", label: "SUB DE TESORERIA" },
    { value: "DIRECCION TECNICA OPERATIVA", label: "DIRECCION TECNICA OPERATIVA" },
    { value: "DIRECTOR TECNICO TALENTO HUMANO SEG SOCIAL", label: "DIRECTOR TECNICO TALENTO HUMANO SEG SOCIAL" },
    { value: "SUBSECRETARIA DE RECURSOS HUMANOS", label: "SUBSECRETARIA DE RECURSOS HUMANOS" },
    { value: "SUBSECRETARIA DE DESARROLLO ADMINISTRATIVO", label: "SUBSECRETARIA DE DESARROLLO ADMINISTRATIVO" },
    { value: "DIRECCION TECNICA CONTROL INTERNO", label: "DIRECCION TECNICA CONTROL INTERNO" },
    { value: "DIRECCION TECNICA BANCO INMOBILIARIO Y S ADMITIVOS", label: "DIRECCION TECNICA BANCO INMOBILIARIO Y S ADMITIVOS" },
    { value: "CONSEJERIA DE COMERCIO EXTERIOR", label: "CONSEJERIA DE COMERCIO EXTERIOR" },
    { value: "OFICINA TERRITORIAL PACIFICO", label: "OFICINA TERRITORIAL PACIFICO" },
    { value: "GESTION DE PAZ", label: "GESTION DE PAZ" },
    { value: "OFICINA DE TRANPARENCIA PARA LA GESTION PUBLICA", label: "OFICINA DE TRANPARENCIA PARA LA GESTION PUBLICA" },
    { value: "SECRETARIA DE MUJER EQUIDAD DE GENERO Y DV SEXUAL", label: "SECRETARIA DE MUJER,EQUIDAD DE GENERO Y DV SEXUAL" },
    { value: "GRUPO DE GESTION DOCUMENTAL", label: "GRUPO DE GESTION DOCUMENTAL" },
    { value: "PRESTACIONES SOCIALES", label: "PRESTACIONES SOCIALES" },
    { value: "NOMINA", label: "NOMINA" },
    { value: "OFICINA DE COOPERACION INTERNACIONAL", label: "OFICINA DE COOPERACION INTERNACIONAL" },
    { value: "OFICINA DE CONTROL DISCIPLINARIO INTERNO", label: "OFICINA DE CONTROL DISCIPLINARIO INTERNO" },
    { value: "SEC ASUNTOS ETNICOS", label: "SEC ASUNTOS ETNICOS" },
    { value: "SEC DE TURISMO", label: "SEC DE TURISMO" },
    { value: "SEC DESARROLLO ECONOMICO Y COMPETITIVIDAD", label: "SEC DESARROLLO ECONOMICO Y COMPETITIVIDAD" },
    { value: "SECRETARIA PRIVADA", label: "SECRETARIA PRIVADA" },
    { value: "DIRECCION OPERATIVA SRIA PRIVADA", label: "DIRECCION OPERATIVA SRIA PRIVADA" },
    { value: "DIRECCION ADMINISTRATIVA SRIA PRIVADA", label: "DIRECCION ADMINISTRATIVA SRIA PRIVADA" },
    { value: "DIRECCION TECNICA CONSEJERIA PARA LA PAZ", label: "DIRECCION TECNICA CONSEJERIA PARA LA PAZ" },
    { value: "ALTA CONSEJERIA PARA MORALIDAD ANTICORRUPCION", label: "ALTA CONSEJERIA PARA MORALIDAD ANTICORRUPCION" },
    { value: "DIRECCION TECNICA ADMINISTRATIVA SRIA GENERAL", label: "DIRECCION TECNICA ADMINISTATIVA SRIA GENERAL" },
    { value: "OFICINA TERRITORIAL CENTRO", label: "OFICINA TERRITORIAL CENTRO" },
    { value: "SUBSECRETARIA DE COBERTURA EDUCACION", label: "SUBSECRETARIA DE COBERTURA EDUCACION" },
    { value: "SUBSECRETARIA DE CALIDAD", label: "SUBSECRETARIA DE CALIDAD" },
    { value: "SUBSECRETARIA ADMINISTRATIVA EDUCACION", label: "SUBSECRETARIA ADMINISTRATIVA EDUCACION" },
    { value: "OFICINA JURIDICA", label: "OFICINA JURIDICA" },
    { value: "SUBSECRETARIA DE ASEGURAMIENTO Y DESARROLLO", label: "SUBSECRETARIA DE ASEGURAMIENTO Y DESARROLLO" },
    { value: "OFICINA DE PLANEACION", label: "OFICINA DE PLANEACION" },
    { value: "OFICINA JURIDICA", label: "OFICINA JURIDICA" },
    { value: "OFICINA COORDINADORA PARA LA GESTION DE LA SALUD", label: "OFICINA COORDINADORA PARA LA GESTION DE LA SALUD" },
    { value: "OFICINA JURIDICA", label: "OFICINA JURIDICA" },
    { value: "SUBSECRETARIA DE MEDIO AMBIENTE Y DESARROLLO SOS", label: "SUBSECRETARIA DE MEDIO AMBIENTE Y DESARROLLO SOS" },
    { value: "DIRECCION TECNICA", label: "DIRECCION TECNICA" },
    { value: "DIRECCION TECNICA", label: "DIRECCION TECNICA" },
    { value: "SUBSECRETARIA PARA LA PROSPERIDAD SOCIAL", label: "SUBSECRETARIA PARA LA PROSPERIDAD SOCIAL" },
    { value: "DIRECCION TECNICA DE COMERCIO", label: "DIRECCION TECNICA DE COMERCIO" },
    { value: "DIRECCION TECNICA", label: "DIRECCION TECNICA" },
    { value: "UNIDAD ADMINISTRATIVA ESPECIAL RENTAS", label: "UNIDAD ADMINISTRATIVA ESPECIAL RENTAS" },
    { value: "SUBDIRECTOR DE UNIDAD ADMINISTRATIVA ESPECIAL", label: "SUBDIRECTOR DE UNIDAD ADMINISTRATIVA ESPECIAL" },
    { value: "DIRECCION TECNICA DE GESTION DE FISCALIZACION", label: "DIRECCIÓN TÉCNICA DE GESTIÓN DE FISCALIZACION" },
    { value: "DIRECCION TECNICA DE LIQUIDACION Y DEVOLUCIONES", label: "DIRECCIÓN TÉCNICA DE LIQUIDACIÓN Y DEVOLUCIONES" },
    { value: "DIRECCION TECNICA DE GESTION JURIDICA", label: "DIRECCIÓN TÉCNICA DE GESTION JURIDICA" },
    { value: "DIRECCION TECNICA DE GESTION DE COBRANZAS", label: "DIRECCIÓN TECNICA DE GESTION DE COBRANZAS" },
    { value: "OFICINA JURIDICA", label: "OFICINA JURIDICA" },
    { value: "SUBDIRECCION DE DESARROLLO REGIONAL", label: "SUBDIRECCION DE DESARROLLO REGIONAL" },
    { value: "SUBDIRECCION DE COOPERACION Y RELACIONES INTERNA", label: "SUBDIRECCION DE COOPERACION Y RELACIONES INTERNA" },
    { value: "SUBDIRECCION DE CONTRATACION", label: "SUBDIRECCION DE CONTRATACION" },
    { value: "SUBDIRECCION DE GESTION Y REPRESENTACION JUDICIAL", label: "SUBDIRECCION DE GESTION Y REPRESENTACIÓN JUDICIAL" },
    { value: "DIRECCION TECNICA", label: "DIRECCIÓN TECNICA" },
    { value: "OFICINA JURIDICA", label: "OFICINA JURÍDICA" },
    { value: "GOBERNADOR A", label: "GOBERNADOR (A)" },
    { value: "OFICINA CASA DEL VALLE EN BOGOTA", label: "OFICINA CASA DEL VALLE EN BOGOTA" },
    { value: "GERENCIA VALLE SIN HAMBRE", label: "GERENCIA VALLE SIN HAMBRE" },
    { value: "OFICINA PRIVADA", label: "OFICINA PRIVADA" },
    { value: "SEC DE PAZ TERRITORIAL Y RECONCILIACION", label: "SEC DE PAZ TERRITORIAL Y RECONCILIACION" },
    { value: "OFICINA DE TRANSPARENCIA PARA LA GESTION PUBLICA", label: "OFICINA DE TRANSPARENCIA PARA LA GESTIÓN PÚBLICA" },
    { value: "DPTO ADMINISTRATIVO HACIENDA Y FINANZAS PUBLICAS", label: "DPTO ADMINISTRATIVO HACIENDA Y FINANZAS PÚBLICAS" },
    { value: "SUBSECRETARIA DE PRESUPUESTO FINANZAS PUBLICAS Y", label: "SUBSECRETARIA DE PRESUPUESTO, FINANZAS PUBLICAS Y" },
    { value: "SUBSECRETARIA DE TESORERIA", label: "SUBSECRETARIA DE TESORERÍA" },
    { value: "CONTADURIA GENERAL DEL DEPARTAMENTO", label: "CONTADURÍA GENERAL DEL DEPARTAMENTO" },
    { value: "SEC GENERAL", label: "SEC GENERAL" },
    { value: "OFICINA DE COMUNICACIONES", label: "OFICINA DE COMUNICACIONES" },
    { value: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO", label: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO" },
    { value: "OFICINA TERRITORIAL NORTE", label: "OFICINA TERRITORIAL NORTE" },
    { value: "OFICINA ADMINISTRATIVA DEL PACIFICO", label: "OFICINA ADMINISTRATIVA DEL PACIFICO" },
    { value: "SEC DE CONVIVENCIA Y SEGURIDAD CIUDADANA", label: "SEC DE CONVIVENCIA Y SEGURIDAD CIUDADANA" },
    { value: "SUBSECRETARIA DE CONVIVENCIA Y SEGURIDAD", label: "SUBSECRETARIA DE CONVIVENCIA Y SEGURIDAD" },
    { value: "SUBSECRETARIA DE ASUNTOS DELEGADOS", label: "SUBSECRETARIA DE ASUNTOS DELEGADOS" },
    { value: "SECRETARIA DE GESTION DEL RIESGO DE DESASTRES", label: "SECRETARÍA DE GESTIÓN DEL RIESGO DE DESASTRES" },
    { value: "SECRETARIA DE EDUCACION", label: "SECRETARIA DE EDUCACIÓN" },
    { value: "SUBSECRETARIA DE COBERTURA", label: "SUBSECRETARIA DE COBERTURA" },
    { value: "SUBSECRETARIA DE CALIDAD EDUCATIVA", label: "SUBSECRETARIA DE CALIDAD EDUCATIVA" },
    { value: "SUBSECRETARIA ADMINISTRATIVA Y FINANCIERA", label: "SUBSECRETARIA ADMINISTRATIVA Y FINANCIERA" },
    { value: "SEC DE SALUD", label: "SEC DE SALUD" },
    { value: "SUBSECRETARIA DE SALUD PUBLICA", label: "SUBSECRETARIA DE SALUD PUBLICA" },
    { value: "SUBSECRETARIA DE ASEGURAMIENTO Y DESARROLLO DE SER", label: "SUBSECRETARIA DE ASEGURAMIENTO Y DESARROLLO DE SER" },
    { value: "SUBSECRETARIA DE GESTION INTEGRADA DE RECURSOS", label: "SUBSECRETARIA DE GESTIÓN INTEGRADA DE RECURSOS" },
    { value: "SECRETARIA DE GESTION HUMANA Y DESARROLLO ORGANIZA", label: "SECRETARIA DE GESTIÓN HUMANA Y DESARROLLO ORGANIZA" },
    { value: "SUBSECRETARIA DE RECURSOS HUMANOS", label: "SUBSECRETARIA DE RECURSOS HUMANOS" },
    { value: "SUB TEC DE DESARROLLO Y BIENESTAR TALENTO HUMANO", label: "SUB TEC DE DESARROLLO Y BIENESTAR TALENTO HUMANO" },
    { value: "DIRECCION TEC BANCO INMOBILIARIO Y SERVICIOS ADMIN", label: "DIRECCIÓN TEC BANCO INMOBILIARIO Y SERVICIOS ADMIN" },
    { value: "DIRECCION TECNICA DE TURISMO", label: "DIRECCIÓN TÉCNICA DE TURISMO" },
    { value: "DIRECCION TECNICA DE COMERCIO", label: "DIRECCIÓN TÉCNICA DE COMERCIO" },
    { value: "GRUPO DE INSPECCION Y VIGILANCIA", label: "GRUPO DE INSPECCIÓN Y VIGILANCIA" },
    { value: "GRUPO DE GESTION ORGANIZACIONAL", label: "GRUPO DE GESTIÓN ORGANIZACIÓNAL" },
    { value: "GRUPO DE COMUNICACIONES", label: "GRUPO DE COMUNICACIONES" },
    { value: "OFICINA DE PLANEACION", label: "OFICINA DE PLANEACION" },
    { value: "GRUPO JURIDICO", label: "GRUPO JURÍDICO" },
    { value: "GRUPO DE ACCESO", label: "GRUPO DE ACCESO" },
    { value: "GRUPO DE PERMANENCIA", label: "GRUPO DE PERMANENCIA" },
    { value: "GRUPO DE EVALUACION EDUCATIVA", label: "GRUPO DE EVALUACIÓN EDUCATIVA" },
    { value: "GRUPO DE MEJORAMIENTO", label: "GRUPO DE MEJORAMIENTO" },
    { value: "GRUPO DE ATENCION AL CIUDADANO", label: "GRUPO DE ATENCIÓN AL CIUDADANO" },
    { value: "GRUPO DE SERVICIOS INFORMATICOS", label: "GRUPO DE SERVICIOS INFORMÁTICOS" },
    { value: "GRUPO DE TALENTO HUMANO", label: "GRUPO DE TALENTO HUMANO" },
    { value: "SUBGRUPO ANALISIS DEL SECTOR", label: "SUBGRUPO ANÁLISIS DEL SECTOR" },
    { value: "SUBGRUPOS PLANES PROGRAMAS Y PROYECTOS", label: "SUBGRUPOS PLANES PROGRAMAS Y PROYECTOS" },
    { value: "SUBGRUPO DE APOYO MUNICIPAL E INSTITUCIONAL", label: "SUBGRUPO DE APOYO MUNICIPAL E INSTITUCIONAL" },
    { value: "SUBGRUPO DE ARCHIVO", label: "SUBGRUPO DE ARCHIVO" },
    { value: "SUBGRUPO DE NOMINA", label: "SUBGRUPO DE NOMINA" },
    { value: "SUBGRUPO PERSONAL", label: "SUBGRUPO PERSONAL" },
    { value: "GRUPO DE GESTION DOCUMENTAL", label: "GRUPO DE GESTION DOCUMENTAL" },
    { value: "GRUPO DE GESTION PRESUPUESTAL Y DE NOMINA", label: "GRUPO DE GESTIÓN PRESUPUESTAL Y DE NOMINA" },
    { value: "GRUPO DE GESTION JURIDICA", label: "GRUPO DE GESTIÓN JURÍDICA" },
    { value: "GRUPO DE GESTION DE RECURSOS HUMANOS", label: "GRUPO DE GESTIÓN DE RECURSOS HUMANOS" },
    { value: "GRUPO DE DESARROLLO DEL TALENTO HUMANO Y SS", label: "GRUPO DE DESARROLLO DEL TALENTO HUMANO Y SS" },
    { value: "GRUPO DE SERVICIOS GRALES Y DOTACION DE REC FISIC", label: "GRUPO DE SERVICIOS GRALES Y DOTACIÓN DE REC FISIC" },
    { value: "SISTEMA INTEGRADO DE GESTION SIG MECI Y CALIDAD", label: "SISTEMA INTEGRADO DE GESTIÓN SIG (MECI Y CALIDAD)" },
    { value: "DESPACHO DEL GOBERNADOR A", label: "DESPACHO DEL GOBERNADOR(A)" },
    { value: "OFICINA PRIVADA", label: "OFICINA PRIVADA" },
    { value: "OFICINA DEL VALLE EN BOGOTA", label: "OFICINA DEL VALLE EN BOGOTÁ" },
    { value: "OFICINA DE TRANSPARENCIA DE LA GESTION PUBLICA", label: "OFICINA DE TRANSPARENCIA DE LA GESTIÓN PÚBLICA" },
    { value: "SECRETARIA GENERAL", label: "SECRETARÍA GENERAL" },
    { value: "OFICINA DE CONTROL INTERNO", label: "OFICINA DE CONTROL INTERNO" },
    { value: "OFICINA DE CONTROL DISCIPLINARIO INTERNO", label: "OFICINA DE CONTROL DISCIPLINARIO INTERNO" },
    { value: "DPTO ADMINISTRATIVO DE DESARROLLO INSTITUCIONAL", label: "DPTO ADMINISTRATIVO DE DESARROLLO INSTITUCIONAL" },
    { value: "DEPARTAMENTO ADMINISTRATIVO DE PLANEACION", label: "DEPARTAMENTO ADMINISTRATIVO DE PLANEACIÓN" },
    { value: "DPTO ADMIN DE HACIENDA Y FINANZAS PUBLICAS", label: "DPTO. ADMIN. DE HACIENDA Y FINANZAS PÚBLICAS" },
    { value: "DEPARTAMENTO ADMINISTRATIVO DE JURIDICA", label: "DEPARTAMENTO ADMINISTRATIVO DE JURIDICA" },
    { value: "SEC DE EDUCACION", label: "SEC DE EDUCACIÓN" },
    { value: "SECRETARIA DE SALUD", label: "SECRETARÍA DE SALUD" },
    { value: "SECRETARIA DE DESARROLLO SOCIAL Y PARTICIPACION", label: "SECRETARÍA DE DESARROLLO SOCIAL Y PARTICIPACIÓN" },
    { value: "SECRETARIA DE CULTURA", label: "SECRETARÍA DE CULTURA" },
    { value: "SRIA MUJER EQUIDAD DE GENERO Y DIVERSIDAD SEXUAL", label: "SRÍA. MUJER, EQUIDAD DE GÉNERO Y DIVERSIDAD SEXUAL" },
    { value: "SECRETARIA DE ASUNTOS ETNICOS", label: "SECRETARÍA DE ASUNTOS ÉTNICOS" },
    { value: "SECRETARIA DE INFRAESTRUCTURA Y VALORIZACION", label: "SECRETARÍA DE INFRAESTRUCTURA Y VALORIZACIÓN" },
    { value: "SECRETARIA DE VIVIENDA Y HABITAT", label: "SECRETARÍA DE VIVIENDA Y HÁBITAT" },
    { value: "SECRETARIA DE AMBIENTE AGRICULTURA Y PESCA", label: "SECRETARÍA DE AMBIENTE, AGRICULTURA Y PESCA" },
    { value: "SECRETARIA DE TURISMO", label: "SECRETARÍA DE TURISMO" },
    { value: "SECRETARIA DE LAS TIC", label: "SECRETARÍA DE LAS TIC" },
    { value: "SECRETARIA DE MOVILIDAD Y TRANSPORTE", label: "SECRETARIA DE MOVILIDAD Y TRANSPORTE" },
    { value: "SUBSECRETARIA DE INTERNACIONALIZACION", label: "SUBSECRETARÍA DE INTERNACIONALIZACIÓN" },
    { value: "DESP GERENCIA UAE IMPUESTOS RENTAS GEST TRIBU", label: "DESP GERENCIA UAE IMPUESTOS, RENTAS, GEST. TRIBU." },
    { value: "OFICINA ASESORA JURIDICA UAE RENTAS", label: "OFICINA ASESORA JURIDICA -UAE RENTAS" },
    { value: "SUBGERENCIA DE GESTION DE COBRANZAS", label: "SUBGERENCIA DE GESTION DE COBRANZAS" },
    { value: "SUBGERENCIA DE LIQUIDACION Y DEVOLUCIONES", label: "SUBGERENCIA DE LIQUIDACION Y DEVOLUCIONES" },
    { value: "SUBGERENCIA DE GESTION DE FISCALIZACION", label: "SUBGERENCIA DE GESTION DE FISCALIZACION" },
    { value: "SUB TEC DE AFRODESCENDIENTES", label: "SUB TEC DE AFRODESCENDIENTES" },
    { value: "SUB TEC DE INDIGENAS", label: "SUB TEC DE INDIGENAS" },
    { value: "OFICINA JURIDICA CONTROL INTERNO", label: "OFICINA JURIDICA - CONTROL INTERNO" },
    { value: "SUB TEC DE APOYO A LA GESTION CONTROL INTERNO", label: "SUB TEC DE APOYO A LA GESTION - CONTROL INTERNO" },
    { value: "SUB TEC DE APOYO A LA GESTION CONVIVENCIA", label: "SUB TEC DE APOYO A LA GESTION - CONVIVENCIA" },
    { value: "SUB DE GESTION DE CONVIVENCIA Y SEGURIDAD", label: "SUB DE GESTION DE CONVIVENCIA Y SEGURIDAD" },
    { value: "SUB SEC DE ASUNTOS DELEGADOS DE LA NACION CONVI", label: "SUB SEC DE ASUNTOS DELEGADOS DE LA NACION - CONVI" },
    { value: "SUB TEC DE APOYO A LA GESTION DESARROLLO ECONO", label: "SUB TEC DE APOYO A LA GESTION - DESARROLLO ECONO" },
    { value: "SUB SEC DE INTERNACIONALIZACION DESARROLLO ECO", label: "SUB SEC DE INTERNACIONALIZACION - DESARROLLO ECO" },
    { value: "SUB SEC EMPRENDIMIENTO Y DESARROLLO EMP", label: "SUB SEC EMPRENDIMIENTO Y DESARROLLO EMP" },
    { value: "SUB SEC DE COMPETITIVIDAD E INNOVACION", label: "SUB SEC DE COMPETITIVIDAD E INNOVACION" },
    { value: "OFICINA ASESORA JURIDICA DESARROLL INSTITUCIONAL", label: "OFICINA ASESORA JURIDICA - DESARROLL INSTITUCIONAL" },
    { value: "SUB DE GESTION HUMANA DESARROLLO INSTITUCIONAL", label: "SUB DE GESTION HUMANA - DESARROLLO INSTITUCIONAL" },
    { value: "SUB TEC DE RECURSOS FISICOS", label: "SUB TEC DE RECURSOS FISICOS" },
    { value: "SUB TEC DE BANCO INMOBILIARIO", label: "SUB TEC DE BANCO INMOBILIARIO" },
    { value: "SUB TEC DE APOYO A LA GESTION DESARROLLO SOCIAL", label: "SUB TEC DE APOYO A LA GESTION - DESARROLLO SOCIAL" },
    { value: "OFICINA ASESORA JURIDICA DESARROLLO SOCIAL", label: "OFICINA ASESORA JURIDICA - DESARROLLO SOCIAL" },
    { value: "SUB SEC DE PROSPERIDAD Y DESARROLLO SOCIAL", label: "SUB SEC DE PROSPERIDAD Y DESARROLLO SOCIAL" },
    { value: "SUB DE PROGRAMAS SOCIALES Y PARTICIPACION", label: "SUB DE PROGRAMAS SOCIALES Y PARTICIPACION" },
    { value: "OFICINA ASESORA PLANEACION EDUCACION", label: "OFICINA ASESORA PLANEACION - EDUCACION" },
    { value: "OFICINA ASESORA JURIDICA EDUCACION", label: "OFICINA ASESORA JURIDICA - EDUCACION" },
    { value: "SUB TEC DE APOYO A LA GESTION EDUCACION", label: "SUB TEC DE APOYO A LA GESTION - EDUCACION" },
    { value: "SUB SEC DE COBERTURA", label: "SUB SEC DE COBERTURA" },
    { value: "SUB SEC DE CALIDAD", label: "SUB SEC DE CALIDAD" },
    { value: "SUB SEC ADMINISTRATIVA Y FINANCIERA", label: "SUB SEC ADMINISTRATIVA Y FINANCIERA" },
    { value: "SEC DE MUJER EQUIDAD DE GENERO Y DV SEXUAL", label: "SEC DE MUJER, EQUIDAD DE GENERO Y DV SEXUAL" },
    { value: "SUB TEC DE APOYO A LA GESTION EQUIDAD Y GENERO", label: "SUB TEC DE APOYO A LA GESTION - EQUIDAD Y GENERO" },
    { value: "OFICINA TERRITORIAL SUR", label: "OFICINA TERRITORIAL SUR" },
    { value: "SUB TEC DE APOYO A LA GESTION HACIENDA", label: "SUB TEC DE APOYO A LA GESTION - HACIENDA" },
    { value: "SUB TEC DE APOYO A LA GESTION INFRAESTRUCTURA", label: "SUB TEC DE APOYO A LA GESTION - INFRAESTRUCTURA" },
    { value: "SUB SEC DE INFRAESTRUCTURA VIAL", label: "SUB SEC DE INFRAESTRUCTURA VIAL" },
    { value: "SUB SEC DE MACROPROYECTOS", label: "SUB SEC DE MACROPROYECTOS" },
    { value: "SUB SEC DE ASISTENCIA TECNICA Y VALORIZACION", label: "SUB SEC DE ASISTENCIA TECNICA Y VALORIZACION" },
    { value: "SUB TEC DE APOYO A LA GESTION JURIDICA", label: "SUB TEC DE APOYO A LA GESTION - JURIDICA" },
    { value: "SUB DE CONTRATACION JURIDICA", label: "SUB DE CONTRATACION - JURIDICA" },
    { value: "SUB DE REPRESENTACION JUDICIAL", label: "SUB DE REPRESENTACION JUDICIAL" },
    { value: "SUB SEC DE SERVICIOS DE MOVILIDAD", label: "SUB SEC DE SERVICIOS DE MOVILIDAD" },
    { value: "SUB SEC DE SEGURIDAD Y TRANSPORTE", label: "SUB SEC DE SEGURIDAD Y TRANSPORTE" },
    { value: "SEC DE PAZ TERRITORIAL Y RECONCILIACION", label: "SEC DE PAZ TERRITORIAL Y RECONCILIACION" },
    { value: "SUB TEC DE APOYO A LA GESTION PAZ TERRITORIAL", label: "SUB TEC DE APOYO A LA GESTION - PAZ TERRITORIAL" },
    { value: "SUB TECNICA DE APOYO A LA GESTION PLANEACION", label: "SUB TECNICA DE APOYO A LA GESTION- PLANEACION" },
    { value: "OFICINA ASESORA JURIDICA PLANEACION", label: "OFICINA ASESORA JURIDICA - PLANEACION" },
    { value: "SUB DE ESTUDIOS SOCIOECONOMICOS", label: "SUB DE ESTUDIOS SOCIOECONOMICOS" },
    { value: "SUB DE ORDENAMIENTO Y DESARROLLO REGIONAL", label: "SUB DE ORDENAMIENTO Y DESARROLLO REGIONAL" },
    { value: "SUB DE INVERSION PUBLICA", label: "SUB DE INVERSION PUBLICA" },
    { value: "SUB DE ASISTENCIA AL TERRITORIO", label: "SUB DE ASISTENCIA AL TERRITORIO" },
    { value: "OFICINA ASESORA PLANEACION SALUD", label: "OFICINA ASESORA PLANEACION - SALUD" },
    { value: "OFICINA ASESORA JURIDICA SALUD", label: "OFICINA ASESORA JURIDICA - SALUD" },
    { value: "OFICINA ASESORA DEFENSORIA PACIENTE Y PARTICIPACIO", label: "OFICINA ASESORA DEFENSORIA PACIENTE Y PARTICIPACIO" },
    { value: "OFICINA ASESORA PARA LA GESTION DE SALUD", label: "OFICINA ASESORA PARA LA GESTION DE SALUD" },
    { value: "SUB SEC DE SALUD PUBLICA", label: "SUB SEC DE SALUD PUBLICA" },
    { value: "SUB DE ASEGURAMIENTO Y DESARROLLO DE SERVICIOS", label: "SUB DE ASEGURAMIENTO Y DESARROLLO DE SERVICIOS" },
    { value: "SUB SEC ADMINISTRATIVA Y FINANCIERA SALUD", label: "SUB SEC ADMINISTRATIVA Y FINANCIERA - SALUD" },
    { value: "SEC DE LAS TIC", label: "SEC DE LAS TIC" },
    { value: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO", label: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO" },
    { value: "SUB TEC DE APOYO A LA GESTION AGRICULTURA", label: "SUB TEC DE APOYO A LA GESTION - AGRICULTURA" },
    { value: "SUB TEC DE SEGURIDAD ALIMENTARIA", label: "SUB TEC DE SEGURIDAD ALIMENTARIA" },
    { value: "SUB SEC DE ASUNTOS AGROPECUARIOS Y PESQUEROS", label: "SUB SEC DE ASUNTOS AGROPECUARIOS Y PESQUEROS" },
    { value: "SUB DE MEDIO AMBIENTE Y DESARROLLO SOTENIBLE", label: "SUB DE MEDIO AMBIENTE Y DESARROLLO SOTENIBLE" },
    { value: "SUB TEC DE APOYO A LA GESTION CULTURA", label: "SUB TEC DE APOYO A LA GESTION - CULTURA" },
    { value: "SUB TEC DE APOYO A LA GESTION TURISMO", label: "SUB TEC DE APOYO A LA GESTION - TURISMO" },
    { value: "SUB TEC DE APOYO A LA GESTION SEC GENERAL", label: "SUB TEC DE APOYO A LA GESTION - SEC GENERAL" },
    { value: "OFICINA ASESORA JURIDICA SEC GENERAL", label: "OFICINA ASESORA JURIDICA - SEC GENERAL" },
    { value: "OFICINA DE COMUNICACIONES SEC GENERAL", label: "OFICINA DE COMUNICACIONES - SEC GENERAL" },
    { value: "OFICINA PRIVADA", label: "OFICINA PRIVADA" },
    { value: "SUB TEC DE APOYO A LA GESTION VIVIENDA Y HABITA", label: "SUB TEC DE APOYO A LA GESTION - VIVIENDA Y HABITA" },
    { value: "SUB SEC DE GESTION EN VIVIENDA Y HABITAT", label: "SUB SEC DE GESTION EN VIVIENDA Y HABITAT" },
    { value: "SECRETARIA GENERAL", label: "SECRETARIA GENERAL" },
    { value: "DESP GERENCIA UAE IMPUESTOS RENTAS GEST TRIBU", label: "DESP GERENCIA UAE IMPUESTOS, RENTAS, GEST. TRIBU." },
    { value: "OFICINA ASESORA JURIDICA UAE RENTAS", label: "OFICINA ASESORA JURIDICA -UAE RENTAS" },
    { value: "SUBGERENCIA DE GESTION DE COBRANZAS", label: "SUBGERENCIA DE GESTION DE COBRANZAS" },
    { value: "SUBGERENCIA DE LIQUIDACION Y DEVOLUCIONES", label: "SUBGERENCIA DE LIQUIDACION Y DEVOLUCIONES" },
    { value: "SUBGERENCIA DE GESTION DE FISCALIZACION", label: "SUBGERENCIA DE GESTION DE FISCALIZACION" },
    { value: "DEPARTAMENTO ADMINISTRATIVO DE JURIDICA", label: "DEPARTAMENTO ADMINISTRATIVO DE JURIDICA" },
    { value: "SUBDIRECCION TECNICA DE APOYO A LA GESTION", label: "SUBDIRECCION TECNICA DE APOYO A LA GESTION" },
    { value: "OFICINA ASESORA JURIDICA", label: "OFICINA ASESORA JURIDICA" },
    { value: "OFICINA DE COMUNICACIONES", label: "OFICINA DE COMUNICACIONES" },
    { value: "OFICINA TERRITORIAL NORTE", label: "OFICINA TERRITORIAL NORTE" },
    { value: "OFICINAS TERRITORIAL CENTRO", label: "OFICINAS TERRITORIAL CENTRO" },
    { value: "OFICINAS TERRITORIAL PACIFICO", label: "OFICINAS TERRITORIAL PACIFICO" },
    { value: "OFICINAS TERRITORIAL SUR", label: "OFICINAS TERRITORIAL SUR" },
    { value: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO", label: "OFICINA DE RELACIONES PUBLICAS Y PROTOCOLO" },
    { value: "SECRETARIA DE EDUCACION", label: "SECRETARIA DE EDUCACION" },
    { value: "DEPARTAMENTO ADMINISTRATIVO DE DESARROLLO INSTITUC", label: "DEPARTAMENTO ADMINISTRATIVO DE DESARROLLO INSTITUC" },
    { value: "DEPARTAMENTO ADMINISTRATIVO HACIENDA Y FINANZAS PU", label: "DEPARTAMENTO ADMINISTRATIVO HACIENDA Y FINANZAS PÚ" },
    { value: "SECRETARIA DE SALUD", label: "SECRETARIA DE SALUD" },
    { value: "SECRETARIA DE MUJER EQUIDAD DE GENERO Y DIVERSIDAD", label: "SECRETARIA DE MUJER EQUIDAD DE GENERO Y DIVERSIDAD" },
    { value: "SECRETARIA DE ASUNTOS ETNICOS", label: "SECRETARIA DE ASUNTOS ETNICOS" },
    { value: "SECRETARIA DE TURISMO", label: "SECRETARIA DE TURISMO" },
    { value: "SECRETARIA DE LAS TIC", label: "SECRETARIA DE LAS TIC" },
    { value: "SECRETARIA DE CONVIVENCIA Y SEGURIDAD CIUDADANA", label: "SECRETARIA DE CONVIVENCIA Y SEGURIDAD CIUDADANA" },
    { value: "SECRETARIA DE PAZ TERRITORIAL Y RECONCILIACION", label: "SECRETARIA DE PAZ TERRITORIAL Y RECONCILIACION" },
    { value: "SECRETARIA DEL GESTION DEL RIESGO DE DESASTRES", label: "SECRETARIA DEL GESTION DEL RIESGO DE DESASTRES" },
    { value: "OFICINA DE CONTROL INTERNO", label: "OFICINA DE CONTROL INTERNO" },
    { value: "OFICINA ASESORA JURIDICA", label: "OFICINA ASESORA JURIDICA" },
    { value: "SUBDIRECCION TECNICA DE APOYO A LA GESTION", label: "SUBDIRECCION TECNICA DE APOYO A LA GESTION" },
    { value: "OFICINA PRIVADA", label: "OFICINA PRIVADA" },
    { value: "SUBDIRECCION DE RECURSOS FISICOS", label: "SUBDIRECCION DE RECURSOS FISICOS" },
    { value: "SUBDIRECCION TECNICA DE BANCO INMOBILIARIO", label: "SUBDIRECCION TECNICA DE BANCO INMOBILIARIO" },
    { value: "SUB TEC DE DESARROLLO Y BIENESTAR TALENTO HUMANO", label: "SUB TEC DE DESARROLLO Y BIENESTAR TALENTO HUMANO" },
    { value: "SUBDIRECCION DE ESTUDIOS SOCIOECONOMICOS CI TEC IN", label: "SUBDIRECCION DE ESTUDIOS SOCIOECONOMICOS CI,TEC,IN" },
    { value: "UNIDAD ADMINISTRATIVA ESPECIAL DE CATASTRO", label: "UNIDAD ADMINISTRATIVA ESPECIAL DE CATASTRO" },
    { value: "SUBDIRECCION DE INVERSION PUBLICA", label: "SUBDIRECCION DE INVERSION PUBLICA" },
    { value: "SUBDIRECCION DE ASISTENCIA AL TERRITORIO", label: "SUBDIRECCION DE ASISTENCIA AL TERRITORIO" },
    { value: "SUBDIRECCION DE ORDENAMIENTO Y DESARROLLO REGIONAL", label: "SUBDIRECCION DE ORDENAMIENTO Y DESARROLLO REGIONAL" },
    { value: "SUBSECRETARIA DE SALUD PUBLICA", label: "SUBSECRETARIA DE SALUD PUBLICA" },
    { value: "SUB DE ASEGURAMIENTO Y DESARROLLO DE SERVICIOS", label: "SUB DE ASEGURAMIENTO Y DESARROLLO DE SERVICIOS" },
    { value: "SUBSECRETARIA ADMINISTRATIVA Y FINANCIERA", label: "SUBSECRETARIA ADMINISTRATIVA Y FINANCIERA" },
    { value: "SUBSECRETARIA DE PROSPERIDAD Y DESARROLLO SOCIAL", label: "SUBSECRETARIA DE PROSPERIDAD Y DESARROLLO SOCIAL" },
    { value: "SUB SECRE DE PROGRAMAS SOCIALES Y PARTICIPACION", label: "SUB SECRE DE PROGRAMAS SOCIALES Y PARTICIPACION" },
    { value: "SUBSECRETARIA DE EMPRENDIMIENTO Y DESARROLLO EMP", label: "SUBSECRETARIA DE EMPRENDIMIENTO Y DESARROLLO EMP" },
    { value: "SUB SEC DE MEDIO AMBIENTE Y DESARROLLO SOSTENIBLE", label: "SUB SEC DE MEDIO AMBIENTE Y DESARROLLO SOSTENIBLE" },
    { value: "SUBSECRETARIA DE ASUNTOS AGROPECUARIOS Y PESQUEROS", label: "SUBSECRETARIA DE ASUNTOS AGROPECUARIOS Y PESQUEROS" },
    { value: "SUBSECRETARIA DE SERVICIOS DE MOVILIDAD", label: "SUBSECRETARIA DE SERVICIOS DE MOVILIDAD" },
    { value: "SUBSECRETARIA DE SEGURIDAD VIAL Y TRANSPORTE", label: "SUBSECRETARIA DE SEGURIDAD VIAL Y TRANSPORTE" },
    { value: "OFICINA ASESORA DE PLANEACION SEC SALUD", label: "OFICINA ASESORA DE PLANEACION-SEC SALUD" },
    { value: "OFICINA ASESORA JURIDICA SEC SALUD", label: "OFICINA ASESORA JURIDICA-SEC SALUD" },
    { value: "OFI ASESORA DEFENSORIA DEL PACIENTE PARTICIPACION", label: "OFI ASESORA DEFENSORIA DEL PACIENTE /PARTICIPACION" },
    { value: "OFICINA ASESORA PARA LA GESTION DE LA SALUD", label: "OFICINA ASESORA PARA LA GESTION DE LA SALUD" },
    { value: "SUBDIRECCION TECNICA DE APOYO A LA GESTION DESOCIA", label: "SUBDIRECCION TECNICA DE APOYO A LA GESTION DESOCIA" },
    { value: "SUB TECNICA DE APOYO A LA GESTION PLANEACION", label: "SUB TECNICA DE APOYO A LA GESTION- PLANEACION" },
    { value: "OFICINA ASESORA JURIDICA PLANEACION", label: "OFICINA ASESORA JURIDICA - PLANEACION" },
    { value: "OFICINA ASESORA JURIDICA DESARROLLO SOCIAL", label: "OFICINA ASESORA JURIDICA - DESARROLLO SOCIAL" },
    { value: "SUB TEC DE APOYO A LA GESTION CULTURA", label: "SUB TEC DE APOYO A LA GESTION - CULTURA" },
    { value: "SUB TEC DE APOYO A LA GESTION VIVIENDA Y HABITA", label: "SUB TEC DE APOYO A LA GESTION - VIVIENDA Y HABITA" },
    { value: "SUB SEC DE GESTION EN VIVIENDA Y HABITAT", label: "SUB SEC DE GESTION EN VIVIENDA Y HABITAT" },
    { value: "SUB TEC DE SEGURIDAD ALIMENTARIA AGRICULTURA", label: "SUB TEC DE SEGURIDAD ALIMENTARIA - AGRICULTURA" },
    { value: "OFICINA ASESORA JURIDICA INFRAESTRUCTURA", label: "OFICINA ASESORA JURIDICA - INFRAESTRUCTURA" },
    { value: "SUB TEC DE APOYO A LA GESTION TURISMO", label: "SUB TEC DE APOYO A LA GESTION - TURISMO" },
    { value: "SUB TEC DE APOYO A LA GESTION TIC", label: "SUB TEC DE APOYO A LA GESTION - TIC" },
    { value: "SUB TEC DE APOYO A LA GESTION CONVIVENCIA", label: "SUB TEC DE APOYO A LA GESTION - CONVIVENCIA" },
    { value: "SUB TEC DE APOYO A LA GESTION PAZ TERRITORIAL", label: "SUB TEC DE APOYO A LA GESTION - PAZ TERRITORIAL" },
  ],
  classesOptions: [
    { value: "ACCIONES POPULARES", label: "ACCIONES POPULARES" },
    { value: "ACTAS", label: "ACTAS" },
    { value: "ACTUALIZACIÓN DE LINDEROS", label: "ACTUALIZACIÓN DE LINDEROS" },
    { value: "ACUERDOS DISTRITALES", label: "ACUERDOS DISTRITALES" },
    { value: "ACUERDOS CONSEJOS", label: "ACUERDOS CONSEJOS" },
    { value: "ACUERDOS MUNICIPALES", label: "ACUERDOS MUNICIPALES" },
    { value: "AEROFOTOGRAFIA DIGITAL CON CAMARA ANALOGA", label: "AEROFOTOGRAFIA DIGITAL CON CAMARA ANALOGA" },
    { value: "AEROFOTOGRAFIA IMPRESA", label: "AEROFOTOGRAFIA IMPRESA" },
    { value: "AMPLIACIÓN A REQUERIMIENTO ESPECIAL", label: "AMPLIACIÓN A REQUERIMIENTO ESPECIAL" },
    { value: "ANONIMOS", label: "ANONIMOS" },
    { value: "ANULACION DE TORNAGUIAS", label: "ANULACION DE TORNAGUIAS" },
    { value: "AUDIENCIAS DE CONCILIACION", label: "AUDIENCIAS DE CONCILIACION" },
    { value: "AUTO DE CIERRE Y ARCHIVO", label: "AUTO DE CIERRE Y ARCHIVO" },
    { value: "AUTOESTIMACION DE AVALUO CATASTRAL", label: "AUTOESTIMACION DE AVALUO CATASTRAL" },
    { value: "AUTOS", label: "AUTOS" },
    { value: "AUXILIO DE MATERNIDAD", label: "AUXILIO DE MATERNIDAD" },
    { value: "AUXILIOS FUNERARIOS", label: "AUXILIOS FUNERARIOS" },
    { value: "AVALUOS COMERCIALES", label: "AVALUOS COMERCIALES" },
    { value: "BONOS PENSIONALES", label: "BONOS PENSIONALES" },
    { value: "CAMBIO PROPIETARIO POSEEDOR U OCUPANTE", label: "CAMBIO PROPIETARIO POSEEDOR U OCUPANTE" },
    { value: "CARPETA", label: "CARPETA" },
    { value: "CARTA CATASTRAL RURAL (POR PLANCHA)", label: "CARTA CATASTRAL RURAL (POR PLANCHA)" },
    { value: "CARTA CATASTRAL URBANA (POR PLANCHA)", label: "CARTA CATASTRAL URBANA (POR PLANCHA)" },
    { value: "CARTOGRAFIA BASICA Y CATASTRAL POR MUNICIPIO", label: "CARTOGRAFIA BASICA Y CATASTRAL POR MUNICIPIO" },
    { value: "CERTIFICACION", label: "CERTIFICACION" },
    { value: "CERTIFICACIONES DAJ", label: "CERTIFICACIONES DAJ" },
    { value: "CERTIFICACIONES ESAL-SALUD", label: "CERTIFICACIONES ESAL-SALUD" },
    { value: "CERTIFICADO AVALÚO CATASTRAL", label: "CERTIFICADO AVALÚO CATASTRAL" },
    { value: "CERTIFICADO AVALÚO PARA PROYECTO DE INFRAESTRUCTURA", label: "CERTIFICADO AVALÚO PARA PROYECTO DE INFRAESTRUCTURA" },
    { value: "CERTIFICADO CATASTRAL ESPECIAL", label: "CERTIFICADO CATASTRAL ESPECIAL" },
    { value: "CERTIFICADO DE PROPIEDAD O POSESIÓN DE BIENES PERSONA JURÍDICA O NATURAL", label: "CERTIFICADO DE PROPIEDAD O POSESIÓN DE BIENES PERSONA JURÍDICA O NATURAL" },
    { value: "CERTIFICADO PLANO PREDIAL CATASTRAL (CPPC)", label: "CERTIFICADO PLANO PREDIAL CATASTRAL (CPPC)" },
    { value: "CIRCULARES", label: "CIRCULARES" },
    { value: "CITACION", label: "CITACION" },
    { value: "COMUNICAC. OFICIALES", label: "COMUNICAC. OFICIALES" },
    { value: "COMUNICACION INFORMATIVA", label: "COMUNICACION INFORMATIVA" },
    { value: "CONSULTA O CONCEPTO", label: "CONSULTA O CONCEPTO" },
    { value: "CONTRATOS", label: "CONTRATOS" },
    { value: "CONVENIOS", label: "CONVENIOS" },
    { value: "CORRECCION DE FORMACION Y AREA CATASTRAL", label: "CORRECCION DE FORMACION Y AREA CATASTRAL" },
    { value: "CROQUIS DEL PREDIO", label: "CROQUIS DEL PREDIO" },
    { value: "DECLARACION DE IMPUESTO AL CONSUMO", label: "DECLARACION DE IMPUESTO AL CONSUMO" },
    { value: "DECLARACIONES TRIBUTARIAS", label: "DECLARACIONES TRIBUTARIAS" },
    { value: "DECRETOS", label: "DECRETOS" },
    { value: "DENUNCIA", label: "DENUNCIA" },
    { value: "DERECHOS DE PETICIÒN", label: "DERECHOS DE PETICIÒN" },
    { value: "DESACATO", label: "DESACATO" },
    { value: "DEVOLUCION Y/O COMPENSACION PAGOS EXCESO O NO DEBIDO", label: "DEVOLUCION Y/O COMPENSACION PAGOS EXCESO O NO DEBIDO" },
    { value: "EMPLAZAMIENTO PARA CORREGIR", label: "EMPLAZAMIENTO PARA CORREGIR" },
    { value: "EMPLAZAMIENTO PARA DECLARAR", label: "EMPLAZAMIENTO PARA DECLARAR" },
    { value: "ENGLOBE Y DESENGLOBE", label: "ENGLOBE Y DESENGLOBE" },
    { value: "ESPACIOMAPAS", label: "ESPACIOMAPAS" },
    { value: "ESTADÍSTICAS CATASTRALES DIGITALES Y/O IMPRESAS", label: "ESTADÍSTICAS CATASTRALES DIGITALES Y/O IMPRESAS" },
    { value: "ESTUDIOS MULTITEMP", label: "ESTUDIOS MULTITEMP" },
  ],
};