import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "react-phone-number-input/style.css";
import "./styles/businessBackofficeStyles.scss";
import {
  Row,
  Col,
  Table,
  Modal,
  ModalBody,
  Spinner,
  Form,
  FormGroup,
  Label,
  Input,
  ModalHeader,
  ModalFooter,
  Button,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
import Employees from "./Employees";
import DocumentAnalysisModule from "./Extras/DocumentAnalysisModule";
import {
  downloadOfficesExcel,
  downloadExcel,
  downloadFinaktivaExtractExcel,
  filterOffices,
  getDocumentAlerts,
  getExtractedData,
  updateExtractionField,
  filterPqrs,
  getMantisBusinessUsers,
  assignPqrstoUser,
  getMantisBusinessGroups,
  assignPqrstoGroup,
  getInternalMessages,
  //updatePqrsPriority,
} from "../ApiCalls/mantisBusiness";
import { stringify } from "../utils/dataExtractionUtils";
import { saveAs } from "file-saver";
import { formatMoney } from "../utils/dataExtractionUtils";
import { useLocation } from "react-router-dom";
import DocumentActions from "./DocumentActions";

const select = (state) => {
  return {
    userId: state.auth.userId,
    token: state.auth.token,
    mantisBusiness: state.auth.mantisBusiness,
    user: state.auth.user,
  };
};

const pqrsSourceOptions = [
  { value: "sede_electronica", label: "Sede Electrónica" },
  { value: "email", label: "Correo Electrónico" },
  { value: "ventanilla_unica", label: "Ventanilla Única" },
];

function Correspondence(props) {
  const [selectedRegisters, setSelectedRegisters] = useState([]);
  const [showingFileDetails, setShowingFileDetails] = useState(false);
  const [extractedData, setExtractedData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRegister, setSelectedRegister] = useState(null);
  const [shownAlerts, setShownAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(false);
  const [registersPerPage, setRegistersPerPage] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [showAssignUserModal, setShowAssignUserModal] = useState(false);

  const [internalMessages, setInternalMessages] = useState([]);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id")?.replace(/_/g, " ");

  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [filtering, setFiltering] = useState(false);
  const [showingFilteredData, setShowingFilteredData] = useState(false);
  const [tab, setTab] = useState(
    props.mantisBusiness.clientType === "PQRS" ? "RECEIVED" : ""
  );

  //PQRS
  const [tangeloSelectedCase, setTangeloSelectedCase] = useState(null);
  const [tangeloShownFile, setTangeloShownFile] = useState(null);
  const [tangeloShownEmail, setTangeloShownEmail] = useState(null);
  const [updatingCases, setUpdatingCases] = useState({});
  const [radicadoFilter, setRadicadoFilter] = useState("");
  const [vencimientoFilter, setVencimientoFilter] = useState("");
  const [sourceFilter, setSourceFilter] = useState("");
  const [showAssignCaseModal, setShowAssignCaseModal] = useState(false);
  const [pqrsCaseToAssign, setPqrsCaseToAssign] = useState(null);
  const [pqrsCaseToUpdatePriority, setPqrsCaseToUpdatePriority] = useState(null);
  const [businessUsers, setBusinessUsers] = useState([]);
  const [businessGroups, setBusinessGroups] = useState([]);
  const [assigningCase, setAssigningCase] = useState(false);
  const [selectedUserToAssign, setSelectedUserToAssign] = useState(null);
  const [selectedGroupToAssign, setSelectedGroupToAssign] = useState(null);
  const [showPriority, setShowPriority] = useState(false);

  useEffect(() => {
    getData();
    getBusinessUsers();
    getBusinessGroups();
  }, []);

  useEffect(() => {
    getData();
  }, [tab]);

  useEffect(() => {
    if (id && extractedData.length > 0) {
      console.log("Id desde la URL:", id);
      const matchingDocument = extractedData.find(
        (item) => item.main_key === id
      );
      if (matchingDocument) {
        console.log("Documento encontrado:", matchingDocument);
        setSelectedRegister(matchingDocument);
        if (matchingDocument.categoria) {
          setTangeloSelectedCase(matchingDocument);
        }
      } else {
        console.log("Documento no encontrado");
      }
    }
  }, [id, extractedData]);

  const getBusinessUsers = async () => {
    const response = await getMantisBusinessUsers(
      props.mantisBusiness._id,
      props.token
    );
    if (response.success) {
      setBusinessUsers(response.users);
      setSelectedUserToAssign(response.users[0]);
    }
  };

  const getBusinessGroups = async () => {
    const response = await getMantisBusinessGroups(
      props.mantisBusiness._id,
      props.token
    );
    if (response.success) {
      setBusinessGroups(response.groups);
      setSelectedGroupToAssign(
        response.groups.length > 0 ? response.groups[0] : null
      );
    }
  };

  const getNextPage = async () => {
    if (loadingPage) return;
    setLoadingPage(true);
    const response = await getExtractedData(
      props.mantisBusiness._id,
      props.token,
      "rateConfirmations",
      currentPage + 1,
      registersPerPage,
      tab,
      props.user._id
    );
    if (response.success) {
      if (response.data.length === 0) {
        alert("No hay más datos para mostrar");
      } else {
        setExtractedData([...extractedData, ...response.data]);
        setCurrentPage(currentPage + 1);
      }
    }
    setLoadingPage(false);
  };

  const getInternalMessages = async () => {
    const response = await getInternalMessages(props.mantisBusiness._id, props.token);
    if (response.success) {
      setInternalMessages(response.data);
    }
  };

  const handleFilterPqrs = async () => {
    setFiltering(true);
    const response = await filterPqrs(
      props.token,
      {
        startDate: startDateFilter,
        endDate: endDateFilter,
        radicado: radicadoFilter,
        status: statusFilter,
        source: sourceFilter,
        vencimiento: vencimientoFilter,
      },
      registersPerPage,
      props.mantisBusiness._id,
      props.mantisBusiness.clientType,
      tab,
      props.user._id
    );
    if (response.success) {
      setShowingFilteredData(true);
      setExtractedData(response.data);
    }
    setFiltering(false);
  };
  
  const handleAssignCaseToUser = async () => {
    setAssigningCase(true);
    setUpdatingCases({
      ...updatingCases,
      [pqrsCaseToAssign.main_key]: true,
    });

    // Determinar si es asignación de grupo o usuario
    const isUserAssignment = showAssignUserModal;
    
    try {
      let response;
      if (isUserAssignment) {
        // Asignación de usuario (Camila Amaya por defecto)
        const defaultUser = { id: "1", name: "Camila Amaya" };
        response = await assignPqrstoGroup(
          props.token,
          pqrsCaseToAssign.main_key,
          defaultUser.id,
          props.mantisBusiness._id,
          props.user._id
        );
      } else {
        // Asignación de grupo (comportamiento original)
        response = await assignPqrstoGroup(
          props.token,
          pqrsCaseToAssign.main_key,
          selectedGroupToAssign._id,
          props.mantisBusiness._id,
          props.user._id
        );
      }

      if (response.success) {
        alert("Caso asignado correctamente");
        
        // Cerrar el modal correspondiente
        if (isUserAssignment) {
          setShowAssignUserModal(false);
        } else {
          setShowAssignCaseModal(false);
        }

        setPqrsCaseToAssign(null);
        setSelectedUserToAssign(null);
        setSelectedGroupToAssign(null);

        let updatedData = [...extractedData];
        const index = updatedData.findIndex(
          (data) => data.main_key === pqrsCaseToAssign.main_key
        );

        if (isUserAssignment) {
          updatedData[index]["usuario_asignado"] = "Camila Amaya";
        } else {
          updatedData[index]["dpto_receptor"] = selectedGroupToAssign.name;
        }
        
        updatedData[index]["asignado_por_ia"] = false;
        setExtractedData(updatedData);
        
      } else {
        alert(`Error al asignar el ${isUserAssignment ? 'usuario' : 'grupo'}`);
      }
    } catch (error) {
      console.error("Error en la asignación:", error);
      alert("Error en el proceso de asignación");
    } finally {
      setAssigningCase(false);
      setUpdatingCases({
        ...updatingCases,
        [pqrsCaseToAssign.main_key]: false,
      });
    }
  };

  // const handlePriorityUpdate = async (newPriority) => {
  //   try {
  //     // Llamada a la API para actualizar la prioridad
  //       const response = await updatePqrsPriority(
  //       pqrsCaseToUpdatePriority.main_key,
  //       newPriority,
  //       props.token
  //     );
  
  //     if (response.success) {
  //       // Actualizar los datos localmente
  //       const updatedData = extractedData.map(item => {
  //         if (item.main_key === pqrsCaseToUpdatePriority.main_key) {
  //           return { ...item, priority: newPriority };
  //         }
  //         return item;
  //       });
  //       setExtractedData(updatedData);
  //       setShowPriority(false);
  //     } else {
  //       alert("Error al actualizar la prioridad");
  //     }
  //   } catch (error) {
  //     console.error("Error al actualizar la prioridad:", error);
  //     alert("Error al actualizar la prioridad");
  //   }
  // };

  const updateTangeloCase = async (mainKey, fieldToUpdate, valueToUpdate) => {
    setUpdatingCases({
      ...updatingCases,
      [mainKey]: true,
    });
    const response = await updateExtractionField(
      props.token,
      fieldToUpdate,
      valueToUpdate,
      mainKey,
      props.mantisBusiness._id
    );
    if (!response.success) {
      alert("Error al actualizar el caso");
    } else {
      let updatedData = [...extractedData];
      const index = updatedData.findIndex((data) => data.main_key === mainKey);
      updatedData[index][fieldToUpdate] = valueToUpdate;
      setExtractedData(updatedData);
    }
    setUpdatingCases({
      ...updatingCases,
      [mainKey]: false,
    });
  };

  const getData = async () => {
    setLoading(true);
    const response = await getExtractedData(
      props.mantisBusiness._id,
      props.token,
      "rateConfirmations",
      currentPage,
      registersPerPage,
      tab,
      props.user._id
    );
    console.log("RESPONSE :b", response);
    if (response.success) {
      console.log("Datos obtenidos:", response.data);
      setExtractedData(response.data);
      if (response.data.length > 0) {
        const headers = [];
        for (let key in response.data[0]) {
          headers.push(key);
        }
        setTableHeaders(headers);
      }
    } else {
      alert("Error al obtener los datos");
    }
    setLoading(false);
  };

  const closeFileDetailsModal = () => {
    setShowingFileDetails(false);
    setSelectedRegisters([]);
    setSelectedRegister(null);
  };

  const closeTangeloCaseModal = () => {
    setTangeloSelectedCase(null);
  };

  return (
    <div>
      <Row
        style={{
          marginTop: "50px",
        }}
      >
        <Col
          xs={11}
          style={{
            margin: "auto",
          }}
        >
          {loading ? (
            <Row>
              <Col
                xs="12"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Spinner
                  color="white"
                  style={{
                    alignSelf: "center",
                  }}
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col xs={6}>
                  <h1 className="business-viewpanel-title">
                    {props.mantisBusiness.name}
                  </h1>
                </Col>
              </Row>

              {props.mantisBusiness.clientType === "PQRS" && (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      marginBottom: "20px",
                    }}
                  >
                    {/* Tab Received - Externo */}
                    <div
                      className={
                        tab === "RECEIVED"
                          ? "active-business-tab"
                          : "business-tab"
                      }
                      onClick={() => setTab("RECEIVED")}
                    >
                      <h4 className="business-viewpanel-tab-title">Externo</h4>
                    </div>
                    {/* Tab Internal - Salida */}
                    <div
                      className={
                        tab === "INTERNO" ? "active-business-tab" : "business-tab"
                      }
                      onClick={() => setTab("INTERNO")}
                    >
                      <h4 className="business-viewpanel-tab-title">Interno</h4>
                    </div>
                    {/* Tab Salida */}
                    <div
                      className={
                        tab === "SENT" ? "active-business-tab" : "business-tab"
                      }
                      onClick={() => setTab("SENT")}
                    >
                      <h4 className="business-viewpanel-tab-title">Salida</h4>
                    </div>
                  </div>
                  {(extractedData.length > 0 ||
                    startDateFilter ||
                    endDateFilter ||
                    radicadoFilter ||
                    statusFilter ||
                    sourceFilter) && (
                    <div className="filter-section-container">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className="filter-section-header"
                          style={{
                            marginRight: "20px",
                          }}
                          onClick={() => setIsFilterExpanded(!isFilterExpanded)}
                        >
                          <i className="fas fa-filter" />
                          <Label
                            className="filter-section-title"
                            style={{
                              marginRight: "10px",
                              textAlign: "center",
                            }}
                          >
                            Filtrar
                          </Label>
                          <i
                            className={`fas fa-chevron-${
                              isFilterExpanded ? "up" : "down"
                            }`}
                          />
                        </div>
                        {showingFilteredData && (
                          <div
                            className="filter-section-header"
                            onClick={() => {
                              setShowingFilteredData(false);
                              setStartDateFilter(null);
                              setEndDateFilter(null);
                              setStatusFilter("");
                              setRadicadoFilter("");
                              getData();
                            }}
                          >
                            <i className="fas fa-broom" />
                            <Label
                              className="filter-section-title"
                              style={{
                                marginRight: "10px",
                                textAlign: "center",
                              }}
                            >
                              Limpiar Filtros
                            </Label>
                          </div>
                        )}
                      </div>

                      <Collapse isOpen={isFilterExpanded}>
                        <Row
                          style={{
                            backgroundColor: "rgba(255, 255, 255, 0.05)",
                            borderRadius: "8px",
                            padding: "15px",
                          }}
                        >
                          <Row>
                            {filtering ? (
                              <>
                                <Col xs={12}>
                                  <Spinner color="white" size={"sm"} />
                                </Col>
                              </>
                            ) : (
                              <>
                                {
                                  <Col xs={2}>
                                    <Form>
                                      <FormGroup>
                                        <Label
                                          for="exampleDate"
                                          className="
                        business-extraction-filter-label"
                                        >
                                          Origen
                                        </Label>
                                        <Input
                                          type="select"
                                          name="origen"
                                          id="origen"
                                          className="business-extraction-filter-input"
                                          onChange={(e) => {
                                            setSourceFilter(e.target.value);
                                          }}
                                          value={sourceFilter}
                                        >
                                          {pqrsSourceOptions.map((option) => (
                                            <option
                                              value={option.value}
                                              className="business-extraction-select-option"
                                            >
                                              {option.label}
                                            </option>
                                          ))}
                                        </Input>
                                      </FormGroup>
                                    </Form>
                                  </Col>
                                }
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="exampleDate"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Fecha Inicial
                                      </Label>
                                      <Input
                                        type="date"
                                        name="startDate"
                                        id="startDate"
                                        placeholder="Fecha"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setStartDateFilter(e.target.value);
                                        }}
                                        value={startDateFilter}
                                      />
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="exampleDate"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Fecha Final
                                      </Label>
                                      <Input
                                        type="date"
                                        name="endDate"
                                        id="endDate"
                                        placeholder="Fecha"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setEndDateFilter(e.target.value);
                                        }}
                                        value={endDateFilter}
                                      />
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={1}>
                                  <Form
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      handleFilterPqrs();
                                    }}
                                  >
                                    <FormGroup>
                                      <Label
                                        for="radicado"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Radicado
                                      </Label>
                                      <Input
                                        type="text"
                                        name="radicado"
                                        id="radicado"
                                        placeholder="# radicado"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setRadicadoFilter(e.target.value);
                                        }}
                                        value={radicadoFilter}
                                      />
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="exampleDate"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Estado
                                      </Label>
                                      <Input
                                        type="select"
                                        name="status"
                                        id="status"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setStatusFilter(e.target.value);
                                        }}
                                        value={statusFilter}
                                      >
                                        <option
                                          value=""
                                          className="business-extraction-select-option"
                                        >
                                          Todos
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="activo"
                                        >
                                          Activo
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="respondido"
                                        >
                                          Respondido
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="archivado"
                                        >
                                          Archivado
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="sin respuesta"
                                        >
                                          Sin Respuesta
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Form>
                                </Col>
                                <Col xs={2}>
                                  <Form>
                                    <FormGroup>
                                      <Label
                                        for="vencimiento"
                                        className="
                        business-extraction-filter-label"
                                      >
                                        Vencimiento
                                      </Label>
                                      <Input
                                        type="select"
                                        name="vencimiento"
                                        id="vencimiento"
                                        className="business-extraction-filter-input"
                                        onChange={(e) => {
                                          setVencimientoFilter(e.target.value);
                                        }}
                                        value={vencimientoFilter}
                                      >
                                        <option
                                          value=""
                                          className="business-extraction-select-option"
                                        >
                                          Todos
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="VENCIDO"
                                        >
                                          Vencidos
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="POR_VENCER"
                                        >
                                          Por vencer
                                        </option>
                                        <option
                                          className="business-extraction-select-option"
                                          value="A_TIEMPO"
                                        >
                                          A tiempo
                                        </option>
                                      </Input>
                                    </FormGroup>
                                  </Form>
                                </Col>
                                {(startDateFilter ||
                                  endDateFilter ||
                                  radicadoFilter ||
                                  statusFilter ||
                                  sourceFilter ||
                                  vencimientoFilter) && (
                                  <Col
                                    xs={1}
                                    style={{
                                      marginTop: "30px",
                                    }}
                                  >
                                    <div
                                      className="business-extraction-filter-button"
                                      onClick={() => {
                                        handleFilterPqrs();
                                      }}
                                    >
                                      <h4 className="business-extraction-filter-button-text">
                                        {filtering ? (
                                          <Spinner color="white" size={"sm"} />
                                        ) : (
                                          "Filtrar"
                                        )}
                                      </h4>
                                    </div>
                                  </Col>
                                )}
                              </>
                            )}
                          </Row>
                        </Row>
                      </Collapse>
                    </div>
                  )}
                </>
              )}

              {extractedData.length === 0 && (
                <h2
                  style={{
                    margin: "auto",
                    fontSize: "1.5em",
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  No hay datos para mostrar
                </h2>
              )}
              <Row style={{ marginTop: "30px" }}>
                <Col xs={12}>
                  {props.mantisBusiness.clientType === "PQRS" ? (
                    <>
                      {extractedData.length > 0 && (
                        <Table borderless>
                          <thead>
                            <tr className="business-extraction-table-header">
                              <th>No. Radicado</th>
                              <th>Fecha Radicado</th>
                              <th>Remitente</th>
                              <th>Receptor</th>
                              <th>Categoria</th>
                              <th>Vencimiento</th>
                              <th>Días</th>
                              <th>Estado</th>
                              <th>Prioridad</th>
                            </tr>
                          </thead>
                          {
                            <tbody>
                              {extractedData.map((register, index) => {
                                const radicado = register["main_key"];
                                let date =
                                  register["fecha_radicado"]?.split("T")[0];
                                let limitDate =
                                  register["vencimiento"]?.split("T")[0];
                                let remitente = register["nombre_solicitante"];
                                let priority = register["prioridad"];
                                //Days between today and the limit date
                                let daysToRespond = Math.floor(
                                  (new Date(limitDate) - new Date()) /
                                    (1000 * 60 * 60 * 24)
                                );
                                let vencimiento =
                                  daysToRespond <= 0
                                    ? "Vencido"
                                    : daysToRespond > 0 && daysToRespond < 3
                                    ? "Próximo a vencer"
                                    : "A tiempo";

                                let receiverName =
                                  register["dpto_receptor"] || "Por definir";
                                let category =
                                  register["categoria"] || "SIN CATEGORÍA";
                                let status = register["estado"];
                                status =
                                  status[0].toUpperCase() +
                                  status.slice(1).replace("_", " ");
                                let assignedByAI = register["asignado_por_ia"];

                                return (
                                  <tr
                                    className="business-extraction-table-row"
                                    key={index}
                                  >
                                    {updatingCases[radicado] ? (
                                      <td
                                        colSpan={7}
                                        style={{
                                          textAlign: "center",
                                          display: "table-cell",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Spinner size={"sm"} />
                                      </td>
                                    ) : (
                                      <>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {radicado ? radicado : "-"}
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {date
                                            ? new Date(date).toLocaleDateString(
                                                "es"
                                              )
                                            : "-"}
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {remitente ? remitente : "REMITENTE"}
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                        >
                                          <div
                                            className={
                                              receiverName === "Por definir"
                                                ? "business-extraction-table-cell-red"
                                                : ""
                                            }
                                            onClick={() => {
                                              setPqrsCaseToAssign(register);
                                              setShowAssignCaseModal(true);
                                            }}
                                          >
                                            {receiverName}
                                            {assignedByAI && (
                                              <>
                                                <i
                                                  id={"caso-" + index}
                                                  className="fas fa-magic"
                                                  style={{
                                                    marginLeft: "5px",
                                                    color: "white",
                                                  }}
                                                  title="Asignado automáticamente"
                                                />
                                                <UncontrolledTooltip
                                                  placement="top"
                                                  target={"caso-" + index}
                                                >
                                                  Asignado automáticamente
                                                </UncontrolledTooltip>
                                              </>
                                            )}
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            verticalAlign: "middle",
                                          }}
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          {category
                                            ? category
                                            : "SIN CLASIFICAR"}
                                        </td>
                                        <td
                                          onClick={() => {
                                            setTangeloSelectedCase(register);
                                          }}
                                        >
                                          <div
                                            className={
                                              daysToRespond >= 3
                                                ? "business-extraction-table-cell-green"
                                                : daysToRespond >= 0 &&
                                                  daysToRespond < 3
                                                ? "business-extraction-table-cell-yellow"
                                                : "business-extraction-table-cell-red"
                                            }
                                          >
                                            {vencimiento}
                                          </div>
                                        </td>

                                        <td>
                                          <div className="business-extraction-filter-input">
                                            {daysToRespond
                                              ? daysToRespond
                                              : "-"}
                                          </div>
                                        </td>
                                        <td>
                                          <div className="business-extraction-filter-input">
                                            {status ? status : "-"}
                                          </div>
                                        </td>
                                        <td>
                                          <div
                                            className={`business-extraction-filter-input ${
                                              priority?.toLowerCase() === "baja"
                                                ? "business-extraction-filter-input-green"
                                                : priority?.toLowerCase() === "media"
                                                ? "business-extraction-filter-input" 
                                                : priority?.toLowerCase() === "alta"
                                                ? "business-extraction-filter-input-red"
                                                : ""
                                            }`}
                                            onClick={() => {
                                              setPqrsCaseToUpdatePriority(register);
                                              setShowPriority(true);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            {priority ? priority : "-"}
                                          </div>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          }
                        </Table>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              {!showingFilteredData && extractedData.length > 0 && (
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Col xs={3}>
                    <div
                      className="business-extraction-filter-button"
                      onClick={() => {
                        getNextPage();
                      }}
                    >
                      <h4 className="business-extraction-filter-button-text">
                        {loadingPage ? (
                          <Spinner color="white" size={"sm"} />
                        ) : (
                          "Cargar más"
                        )}
                      </h4>
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
        </Col>
      </Row>

      {tangeloSelectedCase && (
        <Modal
          className="business-extraction-file-analysis-modal"
          isOpen={tangeloSelectedCase}
          toggle={() => closeTangeloCaseModal()}
          centered
          size="xl"
        >
          <div
            onClick={() => closeTangeloCaseModal()}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "50px",
              marginTop: "10px",
            }}
          >
            <h4
              style={{
                color: "white",
                cursor: "pointer",
                fontSize: "1.5em",
                fontWeight: "bold",
              }}
            >
              X
            </h4>
          </div>
          <ModalBody className="business-extraction-file-analysis-modal-body">
            <Row
              style={{
                width: "100%",
              }}
            >
              <Col
                xs={8}
                style={{
                  marginBottom: "10px",
                  height: "50vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`${tangeloSelectedCase.nombre_solicitante}`}
                  tangeloCase={tangeloSelectedCase}
                  type="tangeloMain"
                  tangeloShownFile={tangeloShownFile}
                  tangeloShownEmail={tangeloShownEmail}
                  selectedDocuments={selectedDocuments}
                  //getAlerts={getAlerts}
                />
              </Col>
              <Col
                xs={4}
                style={{
                  marginBottom: "10px",
                  height: "50vh",
                }}
              >
                <DocumentAnalysisModule
                  title={`Peticiones`}
                  type="tangeloCasePetitions"
                  tangeloCase={tangeloSelectedCase}
                />
              </Col>
              <Col
                xs={8}
                style={{
                  marginBottom: "10px",
                  height: "35vh",
                }}
              >
                <DocumentAnalysisModule
                  tangeloCase={tangeloSelectedCase}
                  type="tangeloCaseDocuments"
                  tangeloShownFile={tangeloShownFile}
                  tangeloShownEmail={tangeloShownEmail}
                  setTangeloShownFile={setTangeloShownFile}
                  setTangeloShownEmail={setTangeloShownEmail}
                  selectedDocuments={selectedDocuments}
                  setSelectedDocuments={setSelectedDocuments}
                />
              </Col>
              <Col
                xs={4}
                style={{
                  marginBottom: "10px",
                  height: "35vh",
                }}
              >
                <DocumentAnalysisModule
                  tangeloCase={tangeloSelectedCase}
                  type="tangeloStatus"
                  updateTangeloCase={updateTangeloCase}
                  title={`Acciones`}
                  tangeloStatus={tangeloSelectedCase.estado}
                  tangeloCategory={tangeloSelectedCase.categoria}
                  changingStatus={updatingCases[tangeloSelectedCase.main_key]}
                  selectedDocuments={selectedDocuments}
                />
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      )}

      {showAssignCaseModal && (
        <Modal
          isOpen={showAssignCaseModal}
          toggle={() => setShowAssignCaseModal(!showAssignCaseModal)}
          centered
          className="business-extraction-modal"
        >
          <ModalHeader className="business-extraction-modal-header">
            <h3
              style={{
                color: "white",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Asignar caso
            </h3>
          </ModalHeader>
          <ModalBody className="business-extraction-modal-body">
            {businessGroups.length > 0 && (
              <Row>
                <Col xs={12}>
                  <Form>
                    <FormGroup>
                      <Label
                        for="userSelect"
                        className="business-extraction-filter-label"
                      >
                        Grupo
                      </Label>
                      <Input
                        type="select"
                        name="groupSelect"
                        id="groupSelect"
                        className="business-extraction-filter-input"
                        onChange={(e) => {
                          setSelectedGroupToAssign(
                            businessGroups.find(
                              (group) => group._id === e.target.value
                            )
                          );
                        }}
                        value={
                          selectedGroupToAssign ? selectedGroupToAssign._id : ""
                        }
                      >
                        {businessGroups.map((group, index) => (
                          <option
                            value={group._id}
                            key={index}
                            className="business-extraction-select-option"
                          >
                            {group.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Form>
                </Col>
              </Row>
            )}
          </ModalBody>
          <ModalFooter className="business-extraction-modal-footer">
            <Button
              color="secondary"
              onClick={() => {
                if (!assigningCase) {
                  setShowAssignCaseModal(false);
                }
              }}
              className="business-extraction-modal-button"
              style={{
                backgroundColor: "#282828",
                color: "white",
                border: "1px solid #444",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Cancelar
            </Button>
            {selectedUserToAssign && (
              <Button
                onClick={handleAssignCaseToUser}
                className="business-extraction-modal-button"
                disabled={assigningCase}
                style={{
                  backgroundColor: "rgba(240, 245, 108, 0.8)",
                  color: "#000",
                  border: "none",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                {assigningCase ? <Spinner color="dark" size="sm" /> : "Asignar"}
              </Button>
            )}
          </ModalFooter>
        </Modal>
      )}
      {showPriority && (
        <Modal
          isOpen={showPriority}
          toggle={() => setShowPriority(!showPriority)}
          centered
          className="business-extraction-modal"
        >
          <ModalBody className="business-extraction-modal-body">
            <h1>Priority</h1>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}

export default connect(select)(Correspondence);

